import { EquipmentType } from 'prosumer-app/+scenario/models';
import { Utils } from 'prosumer-app/core/utils';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { filterNilValue } from '@datorama/akita';

import { ScenarioDetailQuery } from '../scenario-detail';
import { EquipmentInfo } from './equipment.state';
import { EquipmentStore } from './equipment.store';

@Injectable({ providedIn: 'root' })
export class EquipmentQuery extends ScenarioDetailQuery<EquipmentInfo> {
  constructor(
    public store: EquipmentStore,
    readonly keeper: ActiveKeeperService,
  ) {
    super(store, keeper);
  }

  selectActiveEquipments(): Observable<EquipmentInfo[]> {
    return this.selectActiveScenarioId().pipe(
      switchMap((id) => this.selectEquipmentsByScenario(id)),
    );
  }

  selectLoadingOfEntity(id: string): Observable<boolean> {
    return this.selectTruthyEntity(id).pipe(
      map((equipment) => equipment.loading),
    );
  }

  selectTruthyEntity(id: string): Observable<EquipmentInfo> {
    return this.selectEntity(id).pipe(filterNilValue());
  }

  getActiveEquipments(): EquipmentInfo[] {
    return Utils.resolveToEmptyArray(
      this.getAll().filter(
        (equipment) => equipment.scenarioUuid === this.getActiveScenario(),
      ),
    );
  }

  getAllWithType(type: EquipmentType): EquipmentInfo[] {
    return this.getActiveEquipments().filter(
      (equipment) => equipment.type === type,
    );
  }

  private selectActiveScenarioId(): Observable<string> {
    return this.keeper.selectActive().pipe(map((active) => active.scenario));
  }

  private selectEquipmentsByScenario(id: string): Observable<EquipmentInfo[]> {
    return this.selectAll().pipe(
      map((equipments) => this.onlyForScenario(equipments, id)),
    );
  }

  private onlyForScenario(
    equipments: EquipmentInfo[],
    id: string,
  ): EquipmentInfo[] {
    return equipments.filter((equipment) => equipment.scenarioUuid === id);
  }

  private getActiveScenario(): string {
    return this.keeper.getActiveEntities().scenario;
  }

  getDisplayName(entity: EquipmentInfo): string {
    return entity.name;
  }
}
