import { MeterDefinitionDialogData } from 'prosumer-app/+scenario/models/meter-definition.model';
import { MeterDefinitionInfoService } from 'prosumer-app/+scenario/services/meter-definition-info';
import { TimePartitionInfoService } from 'prosumer-app/+scenario/services/time-partition-info';
import { VariationInfoService } from 'prosumer-app/+scenario/services/variation-info/variation-info.service';
import { BaseComponent } from 'prosumer-app/libs/eyes-shared/components/base-component';
import {
  FormFieldErrorMessageMap,
  FormFieldOption,
} from 'prosumer-app/libs/eyes-shared/components/form-field/form-field.model';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared/eyes-shared.module';
import { CustomValidators } from 'prosumer-app/libs/eyes-shared/utilities/validators.util';
import { EntityUpsertButtonModule } from 'prosumer-app/shared/components/entity-upsert-button/entity-upsert-button.module';
import { SharedModule } from 'prosumer-app/shared/shared.module';
import { MeterDefinitionStore } from 'prosumer-app/stores/meter-definition/meter-definition.store';
import { provideUpserter } from 'prosumer-app/stores/scenario-detail/scenario-detail.tokens';
import { tap } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'prosumer-meter-definition-dialog',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    EyesSharedModule,
    SharedModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatFormFieldModule,
    MatProgressBarModule,
    EntityUpsertButtonModule,
  ],
  providers: [provideUpserter(MeterDefinitionStore)],
  templateUrl: './meter-definition-dialog.component.html',
  styleUrl: './meter-definition-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeterDefinitionDialogComponent
  extends BaseComponent
  implements OnInit
{
  private readonly meterDefinitionInfo = inject(MeterDefinitionInfoService);
  private readonly timePartitionInfo = inject(TimePartitionInfoService);
  private readonly variationInfo = inject(VariationInfoService);
  private readonly translateService = inject(TranslateService);
  private readonly fb = inject(FormBuilder);
  readonly dialogRef = inject(MatDialogRef<MeterDefinitionDialogComponent>);
  readonly data = inject<MeterDefinitionDialogData>(MAT_DIALOG_DATA);

  readonly isLoading = toSignal(this.meterDefinitionInfo.isLoadingStore());
  readonly timePartitionsDt = toSignal(
    this.timePartitionInfo.selectMappedForFormSelection(),
  );
  readonly entityOnEdit = toSignal(this.registerEntity(this.data));
  readonly scenarioVariationsDt = signal<FormFieldOption<string>[]>(null);
  readonly onEditLoading = computed(() => this.entityOnEdit()?.loading);

  meterDefinitionForm: UntypedFormGroup;

  ngOnInit(): void {
    this.initForm();
    this.handleScenarioVariationsExist();
    this.handleEntityOnEditMode(this.data);
  }

  onClose(mode: string = null) {
    this.dialogRef.close(mode);
  }

  private initForm() {
    this.meterDefinitionForm = this.fb.group({
      name: this.fb.control(
        '',
        [Validators.required],
        [
          CustomValidators.dataExist(
            this.meterDefinitionInfo.selectAllForActiveScenario(),
            'name',
            this.data.entity,
          ),
        ],
      ),
      timePartitionId: this.fb.control('', [Validators.required]),
      onlyCountPositiveValues: this.fb.control(false),
    });
  }

  private handleScenarioVariationsExist() {
    const vars = this.variationInfo.getAllForActiveScenario();
    if (vars.length > 1) {
      this.scenarioVariationsDt.set(vars);
      this.registerScenarioVariationCtrl(vars);
    }
  }

  private registerScenarioVariationCtrl(variations: FormFieldOption<string>[]) {
    this.meterDefinitionForm.addControl(
      'scenarioVariation',
      this.fb.control(variations[0].value, [Validators.required]),
    );
  }

  private handleEntityOnEditMode(dt: MeterDefinitionDialogData) {
    if (dt.mode === 'edit' && dt.entity) {
      this.meterDefinitionInfo.getSingle(dt.entity.id);
    }
  }

  private registerEntity(dt: MeterDefinitionDialogData) {
    return this.meterDefinitionInfo
      .selectEntity(dt.entity?.id)
      .pipe(
        tap((d) =>
          this.meterDefinitionInfo.patchForm(d, this.meterDefinitionForm),
        ),
      );
  }

  readonly errorMessages: FormFieldErrorMessageMap = {
    name: {
      required: this.translateService.instant(
        'Scenario.dialog.meters.errors.meterDefinition.name.required',
      ),
      dataExist: this.translateService.instant(
        'Scenario.dialog.meters.errors.meterDefinition.name.dataExist',
      ),
    },
    timePartitionId: {
      required: this.translateService.instant(
        'Scenario.dialog.meters.errors.meterDefinition.timePartitionId.required',
      ),
    },
    scenarioVariation: {
      required: this.translateService.instant(
        'Scenario.dialog.meters.errors.meterDefinition.scenarioVariation.required',
      ),
    },
  };
}
