<prosumer-sparkline-table
  parentType="detail"
  detailType="timePartition"
  idField="id"
  [commentable]="true"
  [searchInput]="true"
  [data]="timePartitionDt()"
  [columnsDef]="timePartitionsColumnDef"
  [searchPredicate]="timePartitionSearchPredicate"
  [hideAdd]="isViewOnly()"
  [hideEdit]="isViewOnly()"
  [hideView]="!isViewOnly()"
  (add)="onOpenDialog(isViewOnly(), 'add')"
  (edit)="onOpenDialog(isViewOnly(), 'edit', $event)"
  (delete)="onDeleteTimePartition($event)"
></prosumer-sparkline-table>
