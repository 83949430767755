import isEqual from 'lodash/isEqual';
import { MeterTechnologiesAndMarketsDialogData } from 'prosumer-app/+scenario/models/meter-technologies-and-markets.model';
import { MeterDefinitionInfoService } from 'prosumer-app/+scenario/services/meter-definition-info';
import { MeterTechnologiesAndMarketsInfoService } from 'prosumer-app/+scenario/services/meter-technologies-and-markets-info';
import { TimePartitionInfoService } from 'prosumer-app/+scenario/services/time-partition-info';
import { VariationInfoService } from 'prosumer-app/+scenario/services/variation-info';
import { BINARY_LOCATIONS } from 'prosumer-app/app.references';
import { BaseComponent } from 'prosumer-app/libs/eyes-shared/components/base-component';
import {
  FormFieldErrorMessageMap,
  FormFieldOption,
} from 'prosumer-app/libs/eyes-shared/components/form-field/form-field.model';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared/eyes-shared.module';
import { EntityUpsertButtonModule } from 'prosumer-app/shared/components/entity-upsert-button/entity-upsert-button.module';
import { TimeBlocksIntervalComponent } from 'prosumer-app/shared/components/time-blocks-interval/time-blocks-interval.component';
import { SharedModule } from 'prosumer-app/shared/shared.module';
import { CombinedInputsValidator } from 'prosumer-app/shared/validators/combined-inputs';
import { EnergyGridConnectionQuery } from 'prosumer-app/stores/energy-grid-connection';
import { EnergyVectorQuery } from 'prosumer-app/stores/energy-vector/energy-vector.query';
import { EquipmentQuery } from 'prosumer-app/stores/equipment';
import { MeterTechnologiesAndMarketsCreator } from 'prosumer-app/stores/meter-technologies-and-markets/meter-technologies-and-markets.creator';
import { NodeQuery } from 'prosumer-app/stores/node/node.query';
import { NameValue } from 'prosumer-app/stores/scenario-detail';
import { provideUpserter } from 'prosumer-app/stores/scenario-detail/scenario-detail.tokens';
import { ScenarioGenericQuery } from 'prosumer-app/stores/scenario-generic';
import { MultiSelectComponent } from 'prosumer-shared/components/multi-select';
import { filter, skip, take } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { TECH_AND_MARKETS_COLUMN_DEF } from './config';

@Component({
  selector: 'prosumer-meter-technologies-and-markets-dialog',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MatProgressBarModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    EyesSharedModule,
    SharedModule,
    MultiSelectComponent,
    TimeBlocksIntervalComponent,
    EntityUpsertButtonModule,
  ],
  templateUrl: './meter-technologies-and-markets-dialog.component.html',
  styleUrl: './meter-technologies-and-markets-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideUpserter(MeterTechnologiesAndMarketsCreator)],
})
export class MeterTechnologiesAndMarketsDialogComponent
  extends BaseComponent
  implements OnInit
{
  private readonly techMarketsInfo = inject(
    MeterTechnologiesAndMarketsInfoService,
  );
  private readonly meterDefinitionInfo = inject(MeterDefinitionInfoService);
  private readonly timePartitionInfo = inject(TimePartitionInfoService);
  private readonly variationInfo = inject(VariationInfoService);
  private readonly scenarioQuery = inject(ScenarioGenericQuery);
  private readonly egcQuery = inject(EnergyGridConnectionQuery);
  private readonly translateService = inject(TranslateService);
  private readonly equipQuery = inject(EquipmentQuery);
  private readonly evQuery = inject(EnergyVectorQuery);
  private readonly destroyRef = inject(DestroyRef);
  private readonly nodeQuery = inject(NodeQuery);
  private readonly fb = inject(FormBuilder);
  readonly dialogRef = inject(
    MatDialogRef<MeterTechnologiesAndMarketsDialogComponent>,
  );
  readonly data =
    inject<MeterTechnologiesAndMarketsDialogData>(MAT_DIALOG_DATA);

  readonly meterTechAndMarketsForm: UntypedFormGroup = this.fb.group(
    {
      meterId: this.fb.control('', Validators.required),
      profiles: this.fb.control('', Validators.required),
      energyVectorId: this.fb.control('', Validators.required),
      energyGridConnections: this.fb.control([]),
      equipments: this.fb.control([]),
    },
    {
      validators: CombinedInputsValidator.eitherCtrlsDefined([
        'energyGridConnections',
        'equipments',
      ]),
    },
  );

  private readonly formSubmitted = signal<boolean>(false);
  readonly scenarioVariationsDt = signal<FormFieldOption<string>[]>(null);
  readonly equipmentsDt = signal(
    this.equipQuery.getActiveOptions('associatedEVIds'),
  );
  readonly egcDt = signal(
    this.egcQuery.getActiveOptions('deliveryEnergyVectorId'),
  );
  readonly evDt = signal(this.evQuery.getVectorOptions());
  readonly nodeDt = signal([]);

  readonly activePeriod = toSignal(this.scenarioQuery.selectActivePeriod());
  readonly isLoading = toSignal(this.techMarketsInfo.isLoadingStore());
  readonly meterDefinitionDt = toSignal(
    this.meterDefinitionInfo.selectMappedForFormSelection(),
  );
  private readonly entityOnEdit = toSignal(this.registerEntity(this.data));
  readonly onEditLoading = computed(() => this.entityOnEdit()?.loading);

  readonly ctrlEVValueChange = toSignal(
    this.meterTechAndMarketsForm.get('energyVectorId').valueChanges.pipe(
      filter((d) => !!d),
      skip(this.data.mode === 'edit' ? 1 : 0),
    ),
  );

  readonly filteredEquipDt = computed(
    () =>
      this.filterOptsBasedOnEVSelection(
        this.equipmentsDt(),
        this.deduceInitialEVValue(this.ctrlEVValueChange()),
      ),
    { equal: (prev, next) => isEqual(prev, next) },
  );

  readonly filteredEgcDt = computed(
    () =>
      this.filterOptsBasedOnEVSelection(
        this.egcDt(),
        this.deduceInitialEVValue(this.ctrlEVValueChange()),
      ),
    { equal: (prev, next) => isEqual(prev, next) },
  );

  readonly profilesDataLocation = BINARY_LOCATIONS.METER_TECH_MARKETS_PROF_LOC;
  readonly columnDef = TECH_AND_MARKETS_COLUMN_DEF;

  constructor() {
    super();
    effect(() => this.handleEVAssociatedCtrlsReset(this.ctrlEVValueChange()));
  }

  ngOnInit(): void {
    this.handleScenarioVariationsExist();
    this.handleNodesCtrlExist();
    this.handleEntityOnEditMode(this.data);
    this.subToMeterSelectionChange();
  }

  onClose() {
    this.dialogRef.close();
  }

  onSaveAttempt() {
    this.formSubmitted.set(true);
  }

  private handleScenarioVariationsExist() {
    const vars = this.variationInfo.getAllForActiveScenario();
    if (vars.length > 1) {
      this.scenarioVariationsDt.set(vars);
      this.registerScenarioVariationCtrl(vars);
    }
  }

  private registerScenarioVariationCtrl(variations: FormFieldOption<string>[]) {
    this.meterTechAndMarketsForm.addControl(
      'scenarioVariation',
      this.fb.control(variations[0].value, [Validators.required]),
    );
  }

  private handleNodesCtrlExist() {
    if (this.data.isMultiNode) {
      this.nodeDt.set(this.nodeQuery.getActiveOptions());
      this.meterTechAndMarketsForm.addControl(
        'nodeIds',
        this.fb.control([], [Validators.required]),
      );
    } else {
      // do not ask why...
      this.meterTechAndMarketsForm.addControl(
        'nodeIds',
        this.fb.control([this.nodeQuery.getActiveOptions()[0].value]),
      );
    }
  }

  private handleEntityOnEditMode(dt: MeterTechnologiesAndMarketsDialogData) {
    if (dt.mode === 'edit' && dt.entity) {
      this.techMarketsInfo
        .getSingle(dt.entity.id)
        .pipe(take(1))
        .subscribe((e) =>
          this.techMarketsInfo.patchForm(e, this.meterTechAndMarketsForm),
        );
    }
  }

  private registerEntity(dt: MeterTechnologiesAndMarketsDialogData) {
    return this.techMarketsInfo.selectEntity(dt.entity?.id);
  }

  private subToMeterSelectionChange() {
    this.meterTechAndMarketsForm
      .get('meterId')
      .valueChanges.pipe(
        skip(this.data.mode === 'edit' ? 1 : 0),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((mdId) => this.fetchUniqueTimeBlocks(mdId));
  }

  private handleEVAssociatedCtrlsReset(e: string) {
    ['equipments', 'energyGridConnections'].forEach((ctrlName) => {
      const ctrl = this.meterTechAndMarketsForm.get(ctrlName);
      if (Array.isArray(ctrl.value) && ctrl.value.length > 0) ctrl.reset([]);
    });
  }

  private fetchUniqueTimeBlocks(meterId: string) {
    const tpId = this.meterDefinitionInfo.getEntity(meterId)['timePartitionId'];
    this.timePartitionInfo
      .getUniqueTBsOfATimePartition(tpId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((tp) => {
        this.meterTechAndMarketsForm
          .get('profiles')
          .patchValue([{ data: this.mapTimeBlocksToCtrlFormat(tp) }]);
      });
  }

  private mapTimeBlocksToCtrlFormat(d: string[]) {
    return d.map((e) => ({
      timeBlock: e,
      inputCoefficient: '',
      outputCoefficient: '',
    }));
  }

  private deduceInitialEVValue(v) {
    return v || this.entityOnEdit()?.energyVectorId;
  }

  private filterOptsBasedOnEVSelection(
    opts: NameValue[],
    ev: string,
  ): NameValue[] {
    return opts?.filter((opt) => opt.filteringValue.includes(ev));
  }

  readonly errorMessages: FormFieldErrorMessageMap = {
    meterId: {
      required: this.translateService.instant(
        'Scenario.dialog.meters.errors.technologiesAndMarkets.meterId.required',
      ),
      // unique: this.translateService.instant(
      //   'Scenario.dialog.meters.errors.technologiesAndMarkets.meterId.unique',
      // ),
    },
    scenarioVariation: {
      required: this.translateService.instant(
        'Scenario.dialog.meters.errors.technologiesAndMarkets.scenarioVariation.required',
      ),
      // unique: this.translateService.instant(
      //   'Scenario.dialog.meters.errors.technologiesAndMarkets.scenarioVariation.unique',
      // ),
    },
    profiles: {
      required: this.translateService.instant(
        'Scenario.dialog.meters.errors.technologiesAndMarkets.profiles.required',
      ),
    },
    energyVectorId: {
      required: this.translateService.instant(
        'Scenario.dialog.meters.errors.technologiesAndMarkets.energyVectorId.required',
      ),
    },
    nodeIds: {
      required: this.translateService.instant(
        'Scenario.dialog.meters.errors.technologiesAndMarkets.nodeIds.required',
      ),
    },
    energyGridConnections: {
      eitherCtrlUndefined: this.translateService.instant(
        'Scenario.dialog.meters.errors.technologiesAndMarkets.energyGridConnections.eitherCtrlUndefined',
      ),
    },
    equipments: {
      eitherCtrlUndefined: this.translateService.instant(
        'Scenario.dialog.meters.errors.technologiesAndMarkets.equipments.eitherCtrlUndefined',
      ),
    },
  };
}
