import { TimePartitionInfoService } from 'prosumer-app/+scenario/services/time-partition-info';
import {
  CustomValidators,
  EyesSharedModule,
  FormFieldErrorMessageMap,
} from 'prosumer-app/libs/eyes-shared';
import { BaseComponent } from 'prosumer-app/libs/eyes-shared/components/base-component';
import { SharedModule } from 'prosumer-app/shared';
import { EntityUpsertButtonModule } from 'prosumer-app/shared/components/entity-upsert-button';
import { provideUpserter } from 'prosumer-app/stores';
import {
  TimePartitionState,
  TimePartitionStore,
} from 'prosumer-app/stores/time-partition';
import { take } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { TimePartitionDialogData } from '../../../models/time-partition.model';
import { TimePartitionProfileSummaryComponent } from '../time-partition-profile-summary';

@Component({
  selector: 'prosumer-time-partition-dialog',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    EyesSharedModule,
    SharedModule,
    MatFormFieldModule,
    MatProgressBarModule,
    EntityUpsertButtonModule,
    TimePartitionProfileSummaryComponent,
  ],
  providers: [provideUpserter(TimePartitionStore)],
  templateUrl: './time-partition-dialog.component.html',
  styleUrl: './time-partition-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePartitionDialogComponent
  extends BaseComponent
  implements OnInit
{
  readonly dialogRef = inject(MatDialogRef<TimePartitionDialogComponent>);
  readonly data = inject<TimePartitionDialogData>(MAT_DIALOG_DATA);
  private readonly formBuilder = inject(FormBuilder);
  private readonly timePartitionInfo = inject(TimePartitionInfoService);
  private readonly translateService = inject(TranslateService);

  timePartitionForm: UntypedFormGroup;
  readonly isLoading = toSignal(this.timePartitionInfo.selectLoading());
  readonly isSingleLoading = signal<boolean>(false);

  ngOnInit(): void {
    this.initForm();
    this.getEntityOnEdit(this.data);
  }

  onClose() {
    this.dialogRef.close();
  }

  onProfileDataChange(e: any) {
    this.timePartitionForm.controls.profile.patchValue(e);
  }

  private initForm() {
    this.timePartitionForm = this.formBuilder.group({
      name: this.formBuilder.control(
        '',
        [Validators.required],
        [
          CustomValidators.dataExist(
            this.timePartitionInfo.selectAllForActiveScenario(),
            'name',
            this.data.entity,
          ),
        ],
      ),
      profile: this.formBuilder.control(undefined, [Validators.required]),
    });
  }

  private getEntityOnEdit(dt: TimePartitionDialogData) {
    if (dt.mode === 'edit' && dt.entity) {
      this.isSingleLoading.set(true);
      this.timePartitionInfo
        .getOneAndValidFormValues(dt.entity.id)
        .pipe(take(1))
        .subscribe({
          next: (e: TimePartitionState) =>
            this.timePartitionForm.patchValue({
              name: e.name,
              profile: e.profile,
            }),
          complete: () => this.isSingleLoading.set(false),
        });
    }
  }

  readonly errorMessages: FormFieldErrorMessageMap = {
    name: {
      required: this.translateService.instant(
        'Scenario.messages.timePartition.error.name.required',
      ),
      dataExist: this.translateService.instant(
        'Scenario.messages.timePartition.error.name.dataExist',
      ),
    },
    profile: {
      required: this.translateService.instant(
        'Scenario.messages.timePartition.error.profile.required',
      ),
      dataExist: this.translateService.instant(
        'Scenario.messages.timePartition.error.profile.dataExist',
      ),
    },
  };
}
