import { combineLatest, map } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { MeterDefinitionI } from 'prosumer-app/+scenario/models/meter-definition.model';
import { TimePartitionI } from 'prosumer-app/+scenario/models/time-partition.model';
import { toObject } from 'prosumer-app/libs/eyes-shared/utilities/object.util';
import { MeterDefinitionQuery } from 'prosumer-app/stores/meter-definition/meter-definition.query';
import { ScenarioVariationQuery } from 'prosumer-app/stores/scenario-variation/scenario-variation.query';
import { ScenarioVariationInfo } from 'prosumer-app/stores/scenario-variation/scenario-variation.state';
import { TimePartitionQuery } from 'prosumer-app/stores/time-partition/time-partition.query';

@Injectable({ providedIn: 'root' })
export class MetersRefBuilderService {
  private readonly timePartition = inject(TimePartitionQuery);
  private readonly meterDefinition = inject(MeterDefinitionQuery);
  private readonly variations = inject(ScenarioVariationQuery);

  selectMeterRefs() {
    return combineLatest([
      this.variations.selectAllForActiveScenario(),
      this.timePartition.selectAllForActiveScenario(),
      this.meterDefinition.selectAllForActiveScenario(),
    ]).pipe(map((dt) => this.mapRefs(dt)));
  }

  private mapRefs([a, b, c]) {
    return {
      scenarioVariations: this.objectifyVariations(a),
      timePartitions: this.objectifyTimePartition(b),
      meterDefinitions: this.objectifyMeterDefinition(c),
    };
  }

  private objectifyVariations(
    variations: ScenarioVariationInfo[],
  ): Record<string, string> {
    return this.objectifyUsingId(variations, 'variationUuid');
  }

  private objectifyTimePartition(
    timePartitions: TimePartitionI[],
  ): Record<string, string> {
    return this.objectifyUsingId(timePartitions, 'id', 'name');
  }

  private objectifyMeterDefinition(
    meterDefinitions: MeterDefinitionI[],
  ): Record<string, string> {
    return this.objectifyUsingId(meterDefinitions, 'id', 'name');
  }

  private objectifyUsingId<T>(
    list: T[],
    id: keyof T,
    displayKey = 'name',
  ): Record<string, string> {
    return toObject(list, id as string, displayKey);
  }
}
