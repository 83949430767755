import { delay, of, take } from 'rxjs';

import { Injectable, WritableSignal } from '@angular/core';

@Injectable()
export class TimeBlocksInputHelperService {
  areArraysEqual(arr1: string[] = [], arr2: string[] = []): boolean {
    if (arr1.length !== arr2.length) return false;
    const frequencyMap1 = {};
    const frequencyMap2 = {};
    for (const item of arr1) {
      frequencyMap1[item] = (frequencyMap1[item] || 0) + 1;
    }
    for (const item of arr2) {
      frequencyMap2[item] = (frequencyMap2[item] || 0) + 1;
    }
    for (const key in frequencyMap1) {
      if (frequencyMap1[key] !== frequencyMap2[key]) return false;
    }
    return true;
  }

  extractValofKey(dt: Array<Record<string, string>>, k: string): string[] {
    return dt.map((e) => e[k]);
  }

  animateCopyPasteBtnState(sgnl: WritableSignal<boolean>) {
    sgnl.set(true);
    of(false)
      .pipe(take(1), delay(2000))
      .subscribe(() => sgnl.set(false));
  }
}
