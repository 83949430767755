import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { CascadableDetailStore } from '../cascadable-detail';
import { ScenarioDetailType } from '../scenario-detail';
import { TimePartitionState } from './time-partition.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.timePartition, idKey: 'id' })
export class TimePartitionStore extends CascadableDetailStore<TimePartitionState> {
  edit(id: string, dt: TimePartitionState) {
    return this.cascadeEdit(id, dt);
  }

  upsertLoading(id: string, loading: boolean): void {
    this.setLoading(loading);
    this.upsert(id, { ...this.getEntity(id), loading });
  }
}
