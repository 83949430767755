import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { WithProfilesCreator } from '../creator';
import { ProfileStore } from '../profile';
import { MeterCostAndBoundStateI } from './meter-cost-and-bound.state';
import { MeterCostAndBoundStore } from './meter-cost-and-bound.store';

@Injectable({ providedIn: 'root' })
export class MeterCostAndBoundCreator extends WithProfilesCreator<MeterCostAndBoundStateI> {
  private readonly store = inject(MeterCostAndBoundStore);

  constructor() {
    const profileStore = inject(ProfileStore);
    const keeper = inject(ActiveKeeperService);
    super(profileStore, keeper);
  }

  createEntity(d: MeterCostAndBoundStateI): Observable<unknown> {
    return this.store.create(d);
  }

  updateEntity(d: MeterCostAndBoundStateI) {
    return this.store.edit(d.id, d);
  }
}
