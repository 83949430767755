import { ColumnDefinition, DMY_SLASH_FORMAT } from 'prosumer-libs/eyes-shared';

export const TIME_HORIZON = {
  currentYear: new Date().getFullYear(),
  durationGap: 20,
  acceptableDebounce: 250, // milliseconds
  optimizationYearsLimit: 3,
  defaultHorizon: 'full',
  spinnerSizeYearsToOptimize: 22,
  spinnerColorYearsToOptimize: 'accent',
};

const cascadeTimeHorizon = {
  timePeriod: { displayString: 'Periods', listKey: null, format: 'count' },
  yearsToOptimize: {
    displayString: 'Years to Optimize',
    listKey: 'years',
    format: 'itemize',
  },
};

export const cascadeTimeHorizonMap = new Map(
  Object.entries(cascadeTimeHorizon),
);

export const columndsDefPeriods: ColumnDefinition = {
  year: {
    name: 'Year',
    sortable: true,
  },
  startDate: {
    name: 'Start Date',
    type: 'date',
    format: DMY_SLASH_FORMAT,
    sortable: true,
  },
  endDate: {
    name: 'End Date',
    type: 'date',
    format: DMY_SLASH_FORMAT,
    sortable: true,
  },
  hour: {
    name: 'Number of Hours',
    sortable: true,
    alignment: 'flex-end',
  },
  weight: {
    name: 'Weight',
    sortable: true,
    alignment: 'flex-end',
  },
  actions: {
    name: 'Actions',
    type: 'action',
  },
};

export const columnDefTimePartitions: ColumnDefinition = {
  name: {
    name: 'Name',
    sortable: true,
  },
  actions: {
    name: 'Actions',
    type: 'action',
    flex: '160px',
  },
};
