<mat-form-field appearance="outline" class="eyes-maximize-width">
  <mat-label>{{ labelText() }}</mat-label>
  <mat-select
    multiple
    [formControl]="ctrl"
    [required]="required()"
    [placeholder]="placeholder()"
  >
    @for (opt of availableOptions(); track opt.name) {
      <mat-option [value]="opt.value">{{ opt.name }}</mat-option>
    }
  </mat-select>
  @for (err of ctrl.errors | keyvalue; track err.key) {
    <mat-error
      class="filterchip-error"
      [innerHtml]="errorMessageMap()[err.key]"
    ></mat-error>
  }
  @if (tooltip()) {
    <mat-icon
      matSuffix
      aria-hidden="false"
      class="eyes-input-tooltip-anchor"
      [prosumerTooltip]="tooltip()"
      >help</mat-icon
    >
  }
</mat-form-field>
