@if (tbIntervalsFormArray?.value.length) {
  <div>
    <button
      mat-flat-button
      color="accent"
      type="button"
      (click)="onEditIntervals()"
      data-testid="timeBlocksInterval-btn-edit-intervals"
    >
      <mat-icon aria-hidden="false">edit</mat-icon>
      <span>Interval</span>
    </button>
  </div>
  <mat-tab-group
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    [selectedIndex]="selectedTab.value"
    (selectedIndexChange)="selectedTab.setValue($event)"
  >
    @for (
      interval of tbIntervalsFormArray?.controls;
      track interval.get('startYear').value;
      let i = $index
    ) {
      <mat-tab [formGroup]="interval">
        <ng-template mat-tab-label>
          <!-- [ngClass]="{ 'eyes-warn': isInvalid(i) }" -->
          <div
            class="flex align-items-center"
            [attr.data-testid]="'time-blocks-interval-tab-label'"
          >
            <b>{{ interval.get('startYear').value || 'Start Year' }}</b>
            <mat-icon aria-hidden="false">chevron_right</mat-icon>
            <b>{{ interval.get('endYear').value || 'End Year' }}</b>
            <!-- <span *ngIf="isInvalid(i)" class="eyes-warn">*</span> -->
          </div>
        </ng-template>
        <prosumer-time-blocks-yearly-input
          [formControl]="interval.get('data')"
          [columnDef]="columnDef()"
          [isLoading]="isBinaryLoading() || isLoading()"
          [isViewOnly]="isViewOnly()"
        ></prosumer-time-blocks-yearly-input>
      </mat-tab>
    }
  </mat-tab-group>
} @else {
  <div class="text-center">{{ emptyDataMesssage() }}</div>
}
