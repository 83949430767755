import {
  TimePartitionQuery,
  TimePartitionState,
  TimePartitionStore,
} from 'prosumer-app/stores/time-partition';
import { filter, map, Observable, take } from 'rxjs';

import { inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TimePartitionInfoService {
  private readonly store = inject(TimePartitionStore);
  private readonly query = inject(TimePartitionQuery);

  selectAllForActiveScenario() {
    return this.query.selectAllForActiveScenario();
  }

  selectLoading() {
    return this.query.selectLoading();
  }

  selectMappedForFormSelection() {
    return this.selectAllForActiveScenario().pipe(
      map((l) => this.mapToFormSelection(l)),
    );
  }

  private mapToFormSelection(l: TimePartitionState[]) {
    return l.map((e) => ({ ...e, value: e.id }));
  }

  private filterValidFormValues(e: TimePartitionState) {
    return e && e.profile && e.name ? true : false;
  }

  deleteOne(id: string) {
    this.store.deleteOne(id).pipe(take(1)).subscribe();
  }

  getOneAndValidFormValues(id: string): Observable<TimePartitionState> {
    return this.store
      .improvedGetSingle(id, { slim: '0' })
      .pipe(filter((e) => this.filterValidFormValues(e)));
  }

  getUniqueTBsOfATimePartition(id: string): Observable<string[]> {
    return this.store
      .improvedGetSingle(id, { slim: '1' })
      .pipe(map((e) => e['profile']));
  }
}
