@if (isLoading() || isSingleLoading()) {
  <mat-progress-bar
    color="accent"
    mode="indeterminate"
    data-testid="timePartition-dialog-progress-bar"
  ></mat-progress-bar>
}

<form [formGroup]="timePartitionForm" data-testid="timePartition-dialog-form">
  <mat-dialog-title fxLayout="row" class="container-title pt-3 mb-2">
    <h2 data-testid="timePartition-dialog-title">
      {{ data?.mode | titlecase }}
      {{ 'Scenario.labels.wizard.time.headers.timePartition' | translate }}
    </h2>
    <prosumer-tooltip-anchor
      [message]="'wizard_time.wizard_time_time_partition'"
      data-testid="timePartition-dialog-title-tooltip"
    ></prosumer-tooltip-anchor>
  </mat-dialog-title>

  <mat-dialog-content class="container-content mb-4">
    <div fxLayout="column" class="gap-2">
      <prosumer-input
        [label]="
          'Scenario.labels.wizard.time.timePartition.dialog.name.label'
            | translate
        "
        [placeholder]="
          'Scenario.placeholders.time.timePartition.name' | translate
        "
        [required]="true"
        [control]="timePartitionForm?.controls.name"
        [errorMessageMap]="errorMessages?.name"
        data-testid="timePartition-dialog-form-name"
      >
      </prosumer-input>

      <div fxLayout="row" fxLayoutGap="10px">
        <prosumer-enhanced-loads-input
          formControlName="profile"
          [isTimeBlocks]="true"
          [panelLabel]="'Scenario.labels.timePartition.profile' | translate"
          (dataLoadChanges)="onProfileDataChange($event)"
          data-testid="timePartition-dialog-form-profile"
          [tooltipMessage]="
            'wizard_time.wizard_time_time_partition_time_block_profile'
          "
        ></prosumer-enhanced-loads-input>
        <prosumer-time-partition-profile-summary
          [profileValues]="timePartitionForm.controls.profile.getRawValue()"
        ></prosumer-time-partition-profile-summary>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" class="gap-2 pb-5">
    @if (!data.isViewOnly) {
      <prosumer-entity-upsert-button
        [id]="data.entity?.id"
        [mode]="data.mode"
        [valid]="timePartitionForm.valid"
        [disabled]="timePartitionForm.invalid"
        [data]="timePartitionForm.getRawValue()"
        (ok)="onClose()"
        data-testid="timePartition-dialog-form-btn-submit"
      ></prosumer-entity-upsert-button>
    }
    <button
      mat-flat-button
      type="button"
      [disabled]="isLoading() || isSingleLoading()"
      (click)="onClose()"
      data-testid="timePartition-dialog-form-btn-cancel"
    >
      {{ 'Generic.labels.close' | translate }}
    </button>
  </mat-dialog-actions>
</form>
