import { ReservesForm } from 'prosumer-app/+scenario';

import { CompletionStrategy } from './completion-strategy';

export class ReservesCompletion extends CompletionStrategy<ReservesForm> {
  isFull(form: ReservesForm): boolean {
    return this.getRequiredConditions(form).every(Boolean);
  }

  isHalf(form: ReservesForm): boolean {
    return this.getRequiredConditions(form).some(Boolean);
  }

  private getRequiredConditions(form: ReservesForm): boolean[] {
    return [
      // ...this.getShortCircuitConditions(form.shortCircuit),
    ];
  }

  // private getShortCircuitConditions(circuit: ShortCircuit): boolean[] {
  //   return [
  //     this.hasItems(circuit.energyVectors),
  //     this.hasItems(circuit.equipments),
  //   ];
  // }

  // private hasItems(list: unknown[]): boolean {
  //   return !!Utils.resolveToEmptyArray(list).length;
  // }
}
