import { MeterCostAndBoundStore } from 'prosumer-app/stores/meter-cost-and-bound/meter-cost-and-bound.store';
import { MeterDefinitionQuery } from 'prosumer-app/stores/meter-definition/meter-definition.query';
import { MeterDefinitionStateI } from 'prosumer-app/stores/meter-definition/meter-definition.state';
import { MeterDefinitionStore } from 'prosumer-app/stores/meter-definition/meter-definition.store';
import { MeterTechnologiesAndMarketsStore } from 'prosumer-app/stores/meter-technologies-and-markets/meter-technologies-and-markets.store';
import { delay, filter, forkJoin, map, take } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

const DELAY_TIME_FETCH_AFFECTED = 2000;

@Injectable({ providedIn: 'root' })
export class MeterDefinitionInfoService {
  private readonly store = inject(MeterDefinitionStore);
  private readonly query = inject(MeterDefinitionQuery);
  private readonly meterCostAndBoundsStore = inject(MeterCostAndBoundStore);
  private readonly meterTechMarketsStore = inject(
    MeterTechnologiesAndMarketsStore,
  );

  isLoadingStore() {
    return this.query.selectLoading();
  }

  selectAllForActiveScenario() {
    return this.query.selectAllForActiveScenario();
  }

  selectEntity(id: string) {
    return this.query.selectEntity(id).pipe(filter((d) => !!d));
  }

  getEntity(id: string) {
    return this.query.getEntity(id);
  }

  deleteOne(id: string) {
    this.store
      .deleteOne(id)
      .pipe(take(1), delay(DELAY_TIME_FETCH_AFFECTED))
      .subscribe((_) => this.fetchAffectedEntities());
  }

  postUpdate(mode: 'add' | 'edit') {
    if (mode === 'add') return;
    setTimeout(() => {
      this.fetchAffectedEntities();
    }, DELAY_TIME_FETCH_AFFECTED);
  }

  getSingle(id: string) {
    this.store.improvedGetSingle(id).pipe(take(1)).subscribe();
  }

  patchForm(e: MeterDefinitionStateI, form: UntypedFormGroup) {
    if (this.determineLoadingState(e) && form) {
      const dt = {};
      this.getFormControlKeys().forEach((k) => {
        if (form.controls[k]) dt[k] = e[k];
      });
      form.patchValue(dt);
    }
  }

  selectMappedForFormSelection() {
    return this.selectAllForActiveScenario().pipe(
      map((l) => this.mapToFormSelection(l)),
    );
  }

  private mapToFormSelection(l: MeterDefinitionStateI[]) {
    return l.map((e) => ({ ...e, value: e.id }));
  }

  private determineLoadingState(e: MeterDefinitionStateI) {
    return e?.loading === false;
  }

  private getFormControlKeys() {
    return [
      'name',
      'timePartitionId',
      'onlyCountPositiveValues',
      'scenarioVariation',
    ];
  }

  private fetchAffectedEntities() {
    forkJoin([
      this.meterCostAndBoundsStore.improvedGetAll(),
      this.meterTechMarketsStore.improvedGetAll(),
    ])
      .pipe(take(1))
      .subscribe();
  }
}
