import { TimePartitionI } from 'prosumer-app/+scenario';
import { MeterDefinitionI } from 'prosumer-app/+scenario/models/meter-definition.model';
import { VariationFinder } from 'prosumer-app/+scenario/services/variation-finder.service';
import { toObject } from 'prosumer-app/libs/eyes-shared';
import { HasId } from 'prosumer-app/stores';
import {
  EnergyGridConnectionInfo,
  EnergyGridConnectionQuery,
} from 'prosumer-app/stores/energy-grid-connection';
import {
  EnergyVectorInfo,
  EnergyVectorQuery,
} from 'prosumer-app/stores/energy-vector';
import { EquipmentInfo, EquipmentQuery } from 'prosumer-app/stores/equipment';
import { MeterDefinitionQuery } from 'prosumer-app/stores/meter-definition/meter-definition.query';
import { MobilityRouteQuery } from 'prosumer-app/stores/mobility-route';
import { NettingQuery } from 'prosumer-app/stores/netting';
import { NodeInfo, NodeQuery } from 'prosumer-app/stores/node';
import {
  ScenarioVariationInfo,
  ScenarioVariationQuery,
} from 'prosumer-app/stores/scenario-variation';
import { TimePartitionQuery } from 'prosumer-app/stores/time-partition';
import {
  VehiclesDispatch,
  VehiclesDispatchQuery,
} from 'prosumer-app/stores/vehicles-dispatch';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { TableRefs } from './references.builder.types';

@Injectable({
  providedIn: 'root',
})
export class ReferencesBuilder {
  constructor(
    private readonly nodes: NodeQuery,
    private readonly vectors: EnergyVectorQuery,
    private readonly variations: ScenarioVariationQuery,
    private readonly egcs: EnergyGridConnectionQuery,
    private readonly nettings: NettingQuery,
    private readonly varFinder: VariationFinder,
    private readonly routes: MobilityRouteQuery,
    private readonly dispatches: VehiclesDispatchQuery,
    private readonly equipments: EquipmentQuery,
    private readonly timePartition: TimePartitionQuery,
    private readonly meterDefinition: MeterDefinitionQuery,
  ) {}

  selectRefs(): Observable<TableRefs> {
    return this.combineAllTriggers().pipe(map(() => this.getRefSnapshot()));
  }

  private combineAllTriggers(): Observable<unknown> {
    return combineLatest([
      this.equipments.selectAll(),
      this.nodes.selectAll(),
      this.vectors.selectAll(),
      this.variations.selectAll(),
      this.egcs.selectAll(),
      this.nettings.selectAll(),
      this.routes.selectAll(),
      this.dispatches.selectAll(),
      this.timePartition.selectAll(),
    ]);
  }

  private getRefSnapshot(): TableRefs {
    return {
      energyVectors: this.objectifyVectors(this.vectors.getAll()),
      nodes: this.objectifyNodes(this.nodes.getAll()),
      scenarioVariations: this.objectifyVariations(this.variations.getAll()),
      marketName: this.objectifyEGCs(this.egcs.getAll()),
      netting: this.objectifyHasIDs(this.nettings.getAll()),
      equipments: this.objectifyEquipments(this.equipments.getAll()),
      routes: this.objectifyHasIDs(this.routes.getAll()),
      dispatches: this.objectifyDispatches(this.dispatches.getAll()),
      timePartitions: this.objectifyTimePartition(this.timePartition.getAll()),
      meterDefinitions: this.objectifyMeterDefinition(
        this.meterDefinition.getAll(),
      ),
      booleans: this.objectifyBooleans(),
    };
  }

  private objectifyBooleans(): Record<string, string> {
    return {
      true: 'Yes',
      false: 'No',
    };
  }

  private objectifyEGCs(
    egcs: EnergyGridConnectionInfo[],
  ): Record<string, string> {
    return egcs.reduce<Record<string, string>>((acc, egc) => {
      acc[egc.id] = this.buildMarketName(egc);
      return acc;
    }, {});
  }

  private objectifyEquipments(
    equipments: EquipmentInfo[],
  ): Record<string, string> {
    return equipments.reduce<Record<string, string>>((acc, equipment) => {
      acc[equipment.id] = this.buildEquipmentName(equipment);
      return acc;
    }, {});
  }

  private buildEquipmentName(equipment: EquipmentInfo): string {
    return `${equipment.name} (${this.varFinder.getVariationName(
      equipment.scenarioVariation,
    )})`;
  }

  private buildMarketName(egc: EnergyGridConnectionInfo): string {
    return `${egc.marketName} (${this.varFinder.getVariationName(
      egc.scenarioVariation,
    )})`;
  }

  private objectifyNodes(nodes: NodeInfo[]): Record<string, string> {
    return this.objectifyUsingId(nodes, 'nodeId');
  }

  private objectifyVariations(
    variations: ScenarioVariationInfo[],
  ): Record<string, string> {
    return this.objectifyUsingId(variations, 'variationUuid');
  }

  private objectifyVectors(vecs: EnergyVectorInfo[]): Record<string, string> {
    return this.objectifyUsingId(vecs, 'energyVectorId');
  }

  private objectifyDispatches(
    dispatches: VehiclesDispatch[],
  ): Record<string, string> {
    return this.objectifyUsingId(dispatches, 'id', 'vehicleName');
  }

  private objectifyTimePartition(
    timePartitions: TimePartitionI[],
  ): Record<string, string> {
    return this.objectifyUsingId(timePartitions, 'id', 'name');
  }

  private objectifyMeterDefinition(
    meterDefinitions: MeterDefinitionI[],
  ): Record<string, string> {
    return this.objectifyUsingId(meterDefinitions, 'id', 'name');
  }

  private objectifyHasIDs(hasIds: HasId[]): Record<string, string> {
    return this.objectifyUsingId(hasIds, 'id');
  }

  private objectifyUsingId<T>(
    list: T[],
    id: keyof T,
    displayKey = 'name',
  ): Record<string, string> {
    return toObject(list, id as string, displayKey);
  }
}
