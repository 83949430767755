import { TooltipModule } from 'prosumer-app/libs/eyes-shared/modules/tooltip/tooltip.module';
import { filter } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  forwardRef,
  input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

interface OptionsI {
  name: string;
  value: string;
}

@Component({
  selector: 'prosumer-multi-select',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    TooltipModule,
    MatIconModule,
  ],
  templateUrl: './multi-select.component.html',
  styleUrl: './multi-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
})
export class MultiSelectComponent implements OnInit, ControlValueAccessor {
  readonly options = input<OptionsI[]>([]);
  readonly placeholder = input<string>(undefined);
  readonly labelText = input<string>(undefined);
  readonly allowAllSelection = input<boolean>(false);
  readonly required = input<boolean>(false);
  readonly errors = input<ValidationErrors | null>(null);
  readonly errorMessageMap = input({});
  readonly tooltip = input<string>(null);

  readonly ctrl = new FormControl<string[]>([]);

  readonly availableOptions = computed<OptionsI[]>(() =>
    this.determineOptions(this.options(), this.allowAllSelection()),
  );

  writeValue(v): void {
    if (!v) return;
    this.ctrl.setValue([...v]);
  }
  registerOnTouched(fn: () => void): void {}
  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  onChange(v) {}

  constructor() {
    effect(() => this.ctrl.setErrors(this.errors()));
  }

  ngOnInit(): void {
    this.subToCtrlChange();
  }

  private subToCtrlChange() {
    this.ctrl.valueChanges
      .pipe(
        filter((d) => !this.allowAllSelection() || this.handleAllSelected(d)),
      )
      .subscribe((d) => this.onChange(d));
  }

  private determineOptions(opts: OptionsI[], allowAll: boolean) {
    return allowAll ? [this.optionAlldefaultValue, ...opts] : opts;
  }

  private handleAllSelected(vals: string[]): boolean {
    if (!vals.includes(this.optionAlldefaultValue.value)) return true;
    if (vals.toString() === this.optionAlldefaultValue.value) {
      return true;
    } else {
      this.ctrl.setValue([this.optionAlldefaultValue.value]);
      return false;
    }
  }

  get optionAlldefaultValue() {
    return {
      name: 'All',
      value: 'ALL',
    };
  }
}
