import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';

import { inject, Injectable } from '@angular/core';

import { ScenarioDetailQuery } from '../scenario-detail';
import { TimePartitionState } from './time-partition.state';
import { TimePartitionStore } from './time-partition.store';

@Injectable({ providedIn: 'root' })
export class TimePartitionQuery extends ScenarioDetailQuery<TimePartitionState> {
  constructor() {
    const keeper = inject(ActiveKeeperService);
    const store = inject(TimePartitionStore);
    super(store, keeper);
  }
}
