import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';

import { inject, Injectable } from '@angular/core';

import { ScenarioDetailQuery } from '../scenario-detail';
import { MeterCostAndBoundStateI } from './meter-cost-and-bound.state';
import { MeterCostAndBoundStore } from './meter-cost-and-bound.store';

@Injectable({ providedIn: 'root' })
export class MeterCostAndBoundQuery extends ScenarioDetailQuery<MeterCostAndBoundStateI> {
  constructor() {
    const keeper = inject(ActiveKeeperService);
    const store = inject(MeterCostAndBoundStore);
    super(store, keeper);
  }
}
