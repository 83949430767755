import { MetersRefBuilderService } from 'prosumer-app/+scenario/services/meters-ref-builder/meters-ref-builder.service';
import { StepFormComponent } from 'prosumer-app/libs/eyes-shared/components/step-form/step-form.component';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared/eyes-shared.module';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { MeterCostAndBoundTableComponent } from '../meter-cost-and-bound-table';
import { MeterDefinitionsTableComponent } from '../meter-definitions-table';
import { MeterTechnologiesAndMarketsTableComponent } from '../meter-technologies-and-markets-table/meter-technologies-and-markets-table.component';

@Component({
  selector: 'prosumer-meters-form',
  imports: [
    CommonModule,
    EyesSharedModule,
    MeterDefinitionsTableComponent,
    MeterCostAndBoundTableComponent,
    MeterTechnologiesAndMarketsTableComponent,
  ],
  templateUrl: './meters-form.component.html',
  styleUrl: './meters-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetersFormComponent extends StepFormComponent {
  private readonly refBuilder = inject(MetersRefBuilderService);

  readonly isMultiNode = input<boolean>();
  readonly isViewOnly = input<boolean>();
  readonly isViewMode = computed(
    () => this.isViewOnly() || this.mode === 'read',
  );

  readonly refsMeters = toSignal(this.refBuilder.selectMeterRefs());

  defineForm() {
    return {};
  }
}
