import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import { ColumnDefinition } from 'prosumer-app/libs/eyes-shared/components/base-table/base-table.model';
import { TimePartitionState } from 'prosumer-app/stores/time-partition';
import { TimePartitionInfoService } from 'prosumer-scenario/services/time-partition-info';
import { TimeFormExt } from '../time-form.ext';
import { columnDefTimePartitions } from '../time-form.tokens';
import { TimePartitionDialogComponent } from '../time-partition-dialog';

@Component({
  selector: 'prosumer-time-partition-table',
  templateUrl: './time-partition-table.component.html',
  styleUrl: './time-partition-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TimePartitionTableComponent {
  private readonly infoService = inject(TimePartitionInfoService);
  private readonly dialogService = inject(DialogService);

  readonly isViewOnly = input<boolean>();

  readonly timePartitionsColumnDef: ColumnDefinition = columnDefTimePartitions;
  readonly timePartitionSearchPredicate =
    TimeFormExt.timePartitionSearchPredicate;
  readonly timePartitionDt = toSignal(
    this.infoService.selectAllForActiveScenario(),
  );

  onOpenDialog(
    isViewOnly: boolean,
    mode: 'edit' | 'add',
    e: TimePartitionState = null,
  ) {
    this.dialogService
      .openDialog(TimePartitionDialogComponent, {
        width: 700,
        mode,
        isViewOnly: isViewOnly,
        entity: e,
      })
      .subscribe();
  }

  onDeleteTimePartition(e: any) {
    this.infoService.deleteOne(e.id);
  }
}
