import { PROSUMER_USER_ROLES } from 'prosumer-app/app.references';
import { ManagedDataService } from 'prosumer-app/shared/services/managed-data';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import {
  ScenarioGeneric,
  ScenarioGenericQuery,
} from 'prosumer-app/stores/scenario-generic';
import {
  TimePeriodInfo,
  TimePeriodQuery,
} from 'prosumer-app/stores/time-periods';
import { PipeUtils } from 'prosumer-core/utils';
import { DialogService, UserFacadeService } from 'prosumer-libs/eyes-core';
import {
  ColumnDefinition,
  containsSubstring,
  fadeInAnimation,
  StepFormComponent,
} from 'prosumer-libs/eyes-shared';
import { ScenarioInfoService } from 'prosumer-scenario/services';
import { TimeHorizonCompletion } from 'prosumer-scenario/services/completion-strategies/time-horizon.strategy';
import {
  ScenarioCompletionService,
  ScenarioWizardStep,
} from 'prosumer-scenario/services/scenario-completion';
import { TimePeriodInfoService } from 'prosumer-scenario/services/time-period-info';
import { UpdateStatus } from 'prosumer-shared/directives/scenario-updater';
import { DatesToHourPipe } from 'prosumer-shared/pipes';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  finalize,
  map,
  skip,
  take,
  tap,
} from 'rxjs/operators';

import { ChangeContext, LabelType, Options } from '@angular-slider/ngx-slider';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatRadioChange } from '@angular/material/radio';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Period, ProsumerView, TimeForm } from '../../models';
import { TimeFormExt } from './time-form.ext';
import { columndsDefPeriods, TIME_HORIZON } from './time-form.tokens';
import { TimeHorizonDialogComponent } from './time-horizon-dialog';
import { TimeHorizonService } from './time-horizon.service';

@UntilDestroy()
@Component({
  selector: 'prosumer-time-form',
  templateUrl: './time-form.component.html',
  styleUrls: ['./time-form.component.scss'],
  animations: [fadeInAnimation],
  providers: [ManagedDataService, DatesToHourPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TimeFormComponent
  extends StepFormComponent
  implements AfterViewInit, OnChanges, OnInit
{
  ytoRefreshSeed = 0;
  @ViewChild('durationSlider') sliderComponent: any;
  @Input() loadFormData: ScenarioWizardStep;

  // Project Duration slider options
  sliderOptions: Options = {
    floor: 2010,
    ceil: 2060,
    step: 1,
    draggableRange: true,
    showTicks: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Start Year </b>' + value;
        case LabelType.High:
          return '<b>End Year </b>' + value;
        default:
          return 'Year ' + value;
      }
    },
  };

  // Time Periods Table Metadata
  readonly addPeriod$ = new EventEmitter<any>();
  readonly editPeriod$ = new EventEmitter<any>();
  readonly deletePeriod$ = new EventEmitter<any>();

  readonly periodsColumnsDef: ColumnDefinition = columndsDefPeriods;

  // Observable mania!
  duration$: Observable<number>;
  durationYears$: Observable<Array<number>>;
  isOptimized$: (year) => Observable<any>;
  remainingAllowableYears$: Observable<number>;
  hasReachedLimit$: Observable<boolean>;
  noSelectedYears$: Observable<boolean>;
  timeHorizonPeriods$: Observable<Array<Period>>;
  listItemLoading$ = new BehaviorSubject<Record<string, boolean>>({});
  isClientExpert$ = this.subToUserRole();

  // Subjects to handle the inter-connected control shenanigans!
  durationSubject$ = new BehaviorSubject<Array<number>>([
    TIME_HORIZON.currentYear,
    TIME_HORIZON.currentYear + TIME_HORIZON.durationGap - 1,
  ]);
  yearsSubject$ = new BehaviorSubject<Array<number>>([
    TIME_HORIZON.currentYear,
  ]);
  yearToggled = false;

  yearsToOptimize$ = this.query.yearsToOptimize$.pipe(
    PipeUtils.filterOutUndefined,
    untilDestroyed(this),
  );
  projectDuration$ = this.query.projectDuration$.pipe(
    PipeUtils.filterOutUndefined,
    untilDestroyed(this),
  );
  sliderDisabled$ = new BehaviorSubject<boolean>(false);

  view = ProsumerView.timeHorizon;
  updateStatuses: Record<string, UpdateStatus>;
  isHorizonSelected$ = new BehaviorSubject<boolean>(undefined);
  timePeriodIds: Array<string> = [];

  projectDurationStartValue: number;
  projectDurationEndValue: number;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    private dialogService: DialogService,
    private datesToHourPipe: DatesToHourPipe,
    private completion: ScenarioCompletionService,
    private scenarioInfo: ScenarioInfoService,
    private query: ScenarioGenericQuery,
    private notif: NotificationsService,
    public timePeriodInfo: TimePeriodInfoService,
    private timePeriodQuery: TimePeriodQuery,
    private readonly service: TimeHorizonService,
    private readonly _userFacade: UserFacadeService,
  ) {
    super(ngControl, changeDetector, formBuilder);

    this.subscribeToFormChangesForCompletionTracking();
  }

  ngOnInit(): void {
    this.durationSubject$.subscribe((years) => {
      this.projectDurationStartValue = years[0];
      this.projectDurationEndValue = years[1];
    });
  }

  defineForm() {
    return {
      // Tuple representing start and end year
      projectDuration: [
        [
          TIME_HORIZON.currentYear,
          TIME_HORIZON.currentYear + TIME_HORIZON.durationGap - 1,
        ],
        Validators.required,
      ],
      // Array of years to be optimized
      yearsToOptimize: [[TIME_HORIZON.currentYear], Validators.required],
      // Time Horizon Type
      timeHorizon: [TIME_HORIZON.defaultHorizon, Validators.required],
      // Array of time horizon custom periods (if 'periods' is selected)
      timeHorizonPeriods: [[]],
    };
  }

  ngAfterViewInit() {
    this.yearsSubject$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(TimeFormExt.isYearsNonDistinct),
        skip(2),
      )
      .subscribe(() => this.retrieveTimePeriods());

    this.projectDuration$.subscribe((data) => {
      this.durationSubject$.next([
        data.startYear,
        data.startYear + data.duration - 1,
      ]);
    });
    this.yearsToOptimize$.subscribe((years: number[]) => {
      this.yearsSubject$.next(years);
    });
    this.query.fullTimeHorizon$
      .pipe(PipeUtils.filterOutNullUndefined, take(1))
      .subscribe((data) => {
        this.form.controls.timeHorizon.patchValue(data ? 'full' : 'periods');
        this.isHorizonSelected$.next(data);
      });

    this.timeHorizonPeriods$ = this.timePeriodQuery.timePeriods$.pipe(
      map((periods) =>
        periods.map((period) => ({
          ...period,
          hour: this.datesToHourPipe.transform(
            period.startDate,
            period.endDate,
          ),
          id: period.periodId,
        })),
      ),
      tap(
        (periods) =>
          (this.timePeriodIds = periods.map((period) => period.periodId)),
      ),
      untilDestroyed(this),
    );

    // Clean up 'yearsToOptimize' control when duration has been changed
    this.durationSubject$
      .pipe(this.takeUntilShare())
      .subscribe(([start, end]) => {
        const years = TimeFormExt.filterOptimizedYears(
          [start, end],
          this.yearsSubject$.value,
        );
        this.yearsSubject$.next(years);
      });

    // Emits the difference between end and start year
    this.duration$ = this.durationSubject$.pipe(
      map((durationTuple) => durationTuple[1] - durationTuple[0] + 1),
    );
    // Emits the available years to optimize based on durationChange$
    this.durationYears$ = this.durationSubject$.pipe(
      map(TimeFormExt.getNumbersInBetween),
    );
    this.prepareEventSubscriptions();
  }

  ngOnChanges() {
    this.subscribeLoadData();
  }

  isViewMode = () => {
    const status = this.mode === 'read';
    this.sliderComponent?.setDisabledState(status);
    return status;
  };

  prepareEventSubscriptions = () => {
    // React when addPeriodEvent has emitted
    this.addPeriod$.pipe(this.takeUntilShare()).subscribe(() => {
      if (!this.isViewMode()) {
        this.dialogService
          .openDialog(TimeHorizonDialogComponent, this.getInitDialogData())
          .subscribe();
      }
    });

    // React when editPeriodEvent has emitted
    this.editPeriod$.pipe(this.takeUntilShare()).subscribe((period) => {
      if (!this.isViewMode()) {
        this.dialogService
          .openDialog(TimeHorizonDialogComponent, {
            ...this.getInitDialogData(),
            ...period,
            mode: 'edit',
            timePeriodsData: period,
          })
          .pipe(filter((_data) => _data !== undefined))
          .subscribe();
      }
    });

    // React when deletePeriodEvent has emitted
    this.deletePeriod$
      .pipe(this.takeUntilShare())
      .subscribe((period: TimePeriodInfo) => {
        if (!this.isViewMode()) {
          this.listItemLoading$.next({
            ...this.listItemLoading$.value,
            [period.periodId]: true,
          });
          this.timePeriodInfo.deleteTimePeriod(period.periodId).subscribe({
            next: () => onFinishingDeletion(period),
            error: (error) => onFinishingDeletion(period, error),
          });
        }
      });

    const onFinishingDeletion = (period, error = null) => {
      this.listItemLoading$.next({
        ...this.listItemLoading$.value,
        [period.periodId]: false,
      });
      if (error) {
        this.notif.showError(error.error?.error ?? error.message);
      }
    };
  };

  // Override
  writeValue(timeForm: TimeForm) {
    if (!timeForm) {
      return;
    }
    this.yearsSubject$.next(timeForm.yearsToOptimize);
    super.writeValue(timeForm);
  }

  onDurationChange = (changeContext: ChangeContext) => {
    const startYear = changeContext.value;
    const endYear = changeContext.highValue;

    if (this.isViewMode() || !this.sliderValueChanged(startYear, endYear)) {
      return;
    }

    this.attemptProjectDurationUpdate(startYear, endYear);
  };

  private attemptProjectDurationUpdate(start: number, end: number): void {
    this.disableDurationSlider(true);
    this.service
      .updateProjectDuration(this.getProjectDurationUpdate(start, end))
      .pipe(take(1))
      .subscribe({
        next: (confirmed) => this.undoChangeIfNotConfirmed(!!confirmed),
        error: (error: HttpErrorResponse) => {
          this.notif.showError(
            'ERROR while updating the project duration: \n' +
              JSON.stringify(error?.message),
          );
        },
        complete: () => this.disableDurationSlider(false),
      });
  }

  private undoChangeIfNotConfirmed(confirmed: boolean): void {
    if (!confirmed) {
      this.undoDurationSubject();
    }
  }

  private undoDurationSubject(): void {
    this.durationSubject$.next(this.durationSubject$.value);
  }

  loadingResponse$ = new BehaviorSubject<boolean>(false);

  get spinnerSizeYearsToOptimize() {
    return TIME_HORIZON.spinnerSizeYearsToOptimize;
  }

  get spinnerColorYearsToOptimize() {
    return TIME_HORIZON.spinnerColorYearsToOptimize;
  }

  onYearToggle = (changeContext: MatButtonToggleChange) => {
    const val = changeContext.value;
    const currYears = this.yearsSubject$.value;
    const newYears = changeContext.source.checked
      ? [...currYears, val]
      : [...currYears].filter((year) => year !== val);

    this.noSelectedYears = false;
    this.loadingResponse$.next(true);

    if (newYears.length === 0) {
      this.noSelectedYears = true;
    }

    if (this.isViewMode()) {
      return;
    }

    const updatedData = {
      yearsToOptimize: { defaultYear: 0, years: newYears },
      timeHorizon: { fullTimeHorizon: this.form.value.timeHorizon === 'full' },
    } as ScenarioGeneric;

    this.service
      .updateYearsToOptimize(this.yearsSubject$.value, updatedData)
      .pipe(
        finalize(() => this.loadingResponse$.next(false)),
        take(1),
      )
      .subscribe((response: ScenarioGeneric) => {
        this.ytoRefreshSeed++;
        if (response) this.yearsSubject$.next(response.yearsToOptimize.years);
      });
  };

  _noSelectedYears = false;

  get noSelectedYears() {
    return this._noSelectedYears;
  }

  set noSelectedYears(value: boolean) {
    this._noSelectedYears = value;
  }

  isYearSelected = (year: number) =>
    TimeFormExt.isYearSelected(this.yearsSubject$.value, year);

  hasReachedLimit = () => TimeFormExt.isLimitReached(this.yearsSubject$.value);

  noSelectedYearsOld = () =>
    TimeFormExt.hasNoYearSelected(this.yearsSubject$.value);

  availableYears = () =>
    TimeFormExt.countYearsSelectable(this.yearsSubject$.value);

  periodsSearchPredicate = (data: Period, periodFilter: string) =>
    containsSubstring(String(data.year), periodFilter);

  private subscribeToFormChangesForCompletionTracking(): void {
    const strat = new TimeHorizonCompletion();
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.completion.setForStep(
        ScenarioWizardStep.timeHorizon,
        strat.determineStatus(value),
      );
    });
  }

  private disableDurationSlider(disabled: boolean): void {
    // this.sliderOptions = { ...this.sliderOptions, disabled };
    this.sliderDisabled$.next(disabled);
  }

  private sliderValueChanged(startYear: number, endYear: number): boolean {
    return (
      this.durationSubject$.value[0] !== startYear ||
      this.durationSubject$.value[1] !== endYear
    );
  }

  onStatusChange(data: UpdateStatus) {
    this.updateStatuses = { ...this.updateStatuses, [data.key]: data };
    if (data.status === 'failed') {
      this.form.controls[data.key].setErrors({ saveError: true });
      this.form.controls[data.key].markAsTouched();
    }
    if (this.isHorizonSelected$.value && data.status === 'success') {
      this.timePeriodInfo.removeTimePeriodsFromStore(this.timePeriodIds);
    }
    if (data.status === 'success') {
      this.retrieveTimePeriods();
    }
  }

  onHorizonChange(data: MatRadioChange) {
    this.isHorizonSelected$.next(data.value === 'full');
  }

  private getInitDialogData() {
    const years = TimeFormExt.mapSortOptions(this.yearsSubject$.value);
    return {
      width: 700,
      mode: 'add',
      year: years[0],
      startDate: '',
      endDate: '',
      weight: '1',
      // Options
      yearOptions: years,
      disableClose: true,
      existingTimePeriods$: this.timeHorizonPeriods$,
    };
  }

  public retrieveTimePeriods() {
    if (!this.isHorizonSelected$.value) {
      this.timePeriodInfo.getTimePeriods().pipe(take(1)).subscribe();
    }
  }

  private subscribeLoadData(): void {
    if (this.loadFormData === ScenarioWizardStep.timeHorizon) {
      this.scenarioInfo.getScenario().pipe(take(1)).subscribe();
      this.retrieveTimePeriods();
    }
  }

  private getProjectDurationUpdate(startYear: number, endYear: number) {
    const projecDuration = {
      view: ProsumerView.timeHorizon,
      projectDuration: {
        startYear,
        duration: endYear - startYear + 1,
      },
    };

    if (
      this.yearsSubject$.value.every(
        (year) => !TimeFormExt.numberIsBetween(year, startYear, endYear),
      )
    ) {
      return {
        ...projecDuration,
        yearsToOptimize: { defaultYear: 0, years: [startYear] },
      };
    }

    return projecDuration;
  }

  private subToUserRole() {
    return this._userFacade.clientUserPrefs$.pipe(
      filter((userPrefs) => !!userPrefs),
      map(
        (userPrefs) =>
          (userPrefs || {}).prosumerRole === PROSUMER_USER_ROLES.expert,
      ),
      take(1),
    );
  }
}
