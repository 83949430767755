<div
  class="flow-container"
  fxLayoutAlign="start stretch"
  fxLayoutGap="16px"
  fxLayout="column"
>
  <div fxLayoutAlign="start stretch" fxLayoutGap="16px" fxLayout="column">
    <div
      class="show-hide-subscript"
      fxLayoutAlign="start stretch"
      fxLayoutGap="16px"
      fxLayout="row"
    >
      <mat-form-field appearance="outline" class="select-flow-year">
        <mat-label data-testid="flow-result-years-mat-label">{{
          'Scenario.labels.simulationResults.year' | translate
        }}</mat-label>
        <mat-select
          [formControl]="yearSelectControl"
          [hideSingleSelectionIndicator]="true"
          [placeholder]="'Scenario.labels.simulationResults.year' | translate"
          data-testid="flow-result-years-mat-select"
          required
        >
          <mat-option
            *ngFor="let year of years$ | async"
            [value]="year"
            [attr.data-testid]="'flow-result-years-mat-select-' + year"
          >
            {{ year }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-button-toggle-group
        multiple
        class="show-subscript-margin"
        aria-label="Editor"
        [hideMultipleSelectionIndicator]="true"
      >
        <mat-button-toggle
          (click)="onToggleEdit()"
          [ngClass]="{ 'editor-mode': (showEditor$ | async) }"
          [matTooltip]="'toggle editor'"
          class="editor-toggle-button"
          aria-label="toggle editor"
          checked
        >
          <mat-icon>{{
            (showEditor$ | async) ? 'notes' : 'edit_note'
          }}</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <button
        mat-mini-fab
        color="primary"
        aria-label="refresh"
        matTooltip="refresh"
        class="show-subscript-margin refresh-button"
        (click)="onFlowDataRefresh()"
      >
        <mat-icon>refresh</mat-icon>
      </button>
      <button
        mat-mini-fab
        color="warn"
        aria-label="reset"
        matTooltip="reset"
        class="show-subscript-margin reset-button"
        (click)="onEditorRestore()"
      >
        <mat-icon>restore</mat-icon>
      </button>
    </div>
    <prosumer-flow-results-editor-container
      *ngIf="showEditor$ | async"
      [data]="selectedData$ | async"
      (save)="onEditorSubmit($event)"
    >
    </prosumer-flow-results-editor-container>
    <prosumer-sankey-chart
      *ngIf="selectedData$ | async"
      [sankeyData]="selectedData$ | async"
      [colors]="colors$ | async"
    ></prosumer-sankey-chart>
  </div>
</div>
