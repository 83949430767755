import { MeterCostAndBoundQuery } from 'prosumer-app/stores/meter-cost-and-bound/meter-cost-and-bound.query';
import { MeterCostAndBoundStateI } from 'prosumer-app/stores/meter-cost-and-bound/meter-cost-and-bound.state';
import { MeterCostAndBoundStore } from 'prosumer-app/stores/meter-cost-and-bound/meter-cost-and-bound.store';
import { filter, take } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class MeterCostAndBoundInfoService {
  private readonly store = inject(MeterCostAndBoundStore);
  private readonly query = inject(MeterCostAndBoundQuery);

  isLoadingStore() {
    return this.query.selectLoading();
  }

  selectAllForActiveScenario() {
    return this.query.selectAllForActiveScenario();
  }

  selectEntity(id: string) {
    return this.query.selectEntity(id).pipe(filter((d) => !!d));
  }

  deleteOne(id: string) {
    this.store.deleteOne(id).pipe(take(1)).subscribe();
  }

  getSingle(id: string) {
    this.store.improvedGetSingle(id).pipe(take(1)).subscribe();
  }

  patchForm(e: MeterCostAndBoundStateI, form: UntypedFormGroup) {
    if (e.loading === false && !!form) {
      form.patchValue(
        {
          meterId: e.meterId,
          scenarioVariation: e.scenarioVariation,
          profiles: e.profiles,
        },
        { emitEvent: false },
      );
    }
  }
}
