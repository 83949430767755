export const COST_AND_BOUND_COLUMN_DEF = [
  {
    name: 'Time Blocks',
    key: 'timeBlock',
    role: 'description',
    tooltip: 'wizard_meters.wizard_meters_meter_cost_and_bounds_time_block',
  },
  {
    name: 'Read Value Cost',
    key: 'readValue',
    role: 'input',
    inputType: 'number',
    tooltip: 'wizard_meters.wizard_meters_meter_cost_and_bounds_read_value',
  },
  {
    name: 'Min Read Value',
    key: 'minValue',
    role: 'input',
    inputType: 'number',
    tooltip: 'wizard_meters.wizard_meters_meter_cost_and_bounds_min_read_value',
  },
  {
    name: 'Max Read Value',
    key: 'maxValue',
    role: 'input',
    inputType: 'number',
    tooltip: 'wizard_meters.wizard_meters_meter_cost_and_bounds_maχ_read_value',
  },
];
