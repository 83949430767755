<mat-card appearance="outlined" fxLayout="column" fxLayoutGap="10px">
  <div class="value-toggle-container" fxLayoutAlign="center center">
    <mat-button-toggle-group
      name="valueToggle"
      aria-label="Font Style"
      fxLayout="row wrap"
      [formControl]="valueToggleControl"
      [hideSingleSelectionIndicator]="true"
    >
      <mat-button-toggle
        *ngFor="let option of valueOptions"
        [value]="option?.key"
        [attr.data-testid]="'cashflow-option-' + option?.value"
      >
        {{ option?.value }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div *ngIf="(valueToggle$ | async) === 'Payback'">
    <prosumer-compare-payback
      [id]="'compare-payback'"
      [paybackResults]="paybackResults"
      [colors]="colors$ | async"
    >
    </prosumer-compare-payback>
  </div>

  <div *ngIf="(valueToggle$ | async) === 'Cashflow'">
    <prosumer-compare-cashflow
      [id]="'compare-cashflow'"
      [cashFlowResults]="cashFlowResults"
      [customColors]="customColors"
      [colors]="colors$ | async"
    >
    </prosumer-compare-cashflow>
  </div>
</mat-card>
