import {
  EnergyGridConnection,
  EnergyVector,
  Equipment,
} from 'prosumer-app/+scenario/models';
import { ReservesCompletion } from 'prosumer-app/+scenario/services/completion-strategies/reserves.strategy';
import {
  ScenarioCompletionService,
  ScenarioWizardStep,
} from 'prosumer-app/+scenario/services/scenario-completion';
import { StepFormComponent } from 'prosumer-app/libs/eyes-shared';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NgControl, UntypedFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'prosumer-reserves-form',
  templateUrl: './reserves-form.component.html',
  styleUrls: ['./reserves-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ReservesFormComponent extends StepFormComponent implements OnInit {
  @Input() energyVectors: Array<EnergyVector>;
  @Input() equipments: Array<Equipment>;
  @Input() markets: Array<EnergyGridConnection>;
  @Input() startYear: number;
  @Input() endYear: number;
  @Input() isViewOnly: boolean;
  @Input() scenarioIdentity: {
    projectId: string;
    caseId: string;
    scenarioId: string;
  };

  constructor(
    public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    private completion: ScenarioCompletionService,
  ) {
    super(ngControl, changeDetector, formBuilder);

    this.subscribeToFormForCompletionTracking();
  }

  defineForm() {
    return {};
  }

  canNotAdd = () => this.isViewOnly || this.mode === 'read';
  canNotEdit = () => this.isViewOnly || this.mode === 'read';
  canNotDelete = () => this.isViewOnly || this.mode === 'read';
  canNotView = () => !this.isViewOnly && this.mode !== 'read';

  private subscribeToFormForCompletionTracking(): void {
    const strat = new ReservesCompletion();
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((form) => {
      this.completion.setForStep(
        ScenarioWizardStep.reserves,
        strat.determineStatus(form),
      );
    });
  }
}
