type NameValRef = Record<string, string>;
export interface TableRefs {
  readonly nodes: NameValRef;
  readonly energyVectors: NameValRef;
  readonly scenarioVariations: NameValRef;
  readonly marketName: NameValRef;
  readonly netting: NameValRef;
  readonly routes: NameValRef;
  readonly dispatches: NameValRef;
  readonly equipments: NameValRef;
  readonly booleans: NameValRef;
  readonly timePartitions: NameValRef;
  readonly meterDefinitions: NameValRef;
}

export const TABLE_REF_KEYS: Record<keyof TableRefs, keyof TableRefs> = {
  energyVectors: 'energyVectors',
  equipments: 'equipments',
  marketName: 'marketName',
  netting: 'netting',
  nodes: 'nodes',
  routes: 'routes',
  dispatches: 'dispatches',
  scenarioVariations: 'scenarioVariations',
  booleans: 'booleans',
  timePartitions: 'timePartitions',
  meterDefinitions: 'meterDefinitions',
};
