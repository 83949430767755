import { MeterTechnologiesAndMarketsQuery } from 'prosumer-app/stores/meter-technologies-and-markets/meter-technologies-and-markets.query';
import { MeterTechnologiesAndMarketsStateI } from 'prosumer-app/stores/meter-technologies-and-markets/meter-technologies-and-markets.state';
import { MeterTechnologiesAndMarketsStore } from 'prosumer-app/stores/meter-technologies-and-markets/meter-technologies-and-markets.store';
import { filter, take } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class MeterTechnologiesAndMarketsInfoService {
  private readonly store = inject(MeterTechnologiesAndMarketsStore);
  private readonly query = inject(MeterTechnologiesAndMarketsQuery);

  isLoadingStore() {
    return this.query.selectLoading();
  }

  selectEntity(id: string) {
    return this.query.selectEntity(id).pipe(filter((d) => !!d));
  }

  selectAllForActiveScenario() {
    return this.query.selectAllForActiveScenario();
  }

  deleteOne(id: string) {
    this.store.deleteOne(id).pipe(take(1)).subscribe();
  }

  getSingle(id: string) {
    return this.store.improvedGetSingle(id);
  }

  patchForm(e: MeterTechnologiesAndMarketsStateI, form: UntypedFormGroup) {
    form.patchValue({
      meterId: e.meterId,
      scenarioVariation: e.scenarioVariation,
      profiles: e.profiles,
      energyVectorId: e.energyVectorId,
      equipments: e.equipments,
      energyGridConnections: e.energyGridConnections,
      nodeIds: e.nodeIds,
    });
  }
}
