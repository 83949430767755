import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';

import { inject, Injectable } from '@angular/core';

import { ScenarioDetailQuery } from '../scenario-detail';
import { MeterDefinitionStateI } from './meter-definition.state';
import { MeterDefinitionStore } from './meter-definition.store';

@Injectable({ providedIn: 'root' })
export class MeterDefinitionQuery extends ScenarioDetailQuery<MeterDefinitionStateI> {
  constructor() {
    const keeper = inject(ActiveKeeperService);
    const store = inject(MeterDefinitionStore);
    super(store, keeper);
  }
}
