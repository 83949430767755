import { VariationFinder } from 'prosumer-app/+scenario/services/variation-finder.service';

import { inject, Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { CascadableDetailStore } from '../cascadable-detail';
import { ScenarioDetailType } from '../scenario-detail';
import { NO_VARIATION } from '../scenario-variation/scenario-variation.state';
import { MeterDefinitionStateI } from './meter-definition.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.meterDefinition, idKey: 'id' })
export class MeterDefinitionStore extends CascadableDetailStore<MeterDefinitionStateI> {
  private readonly varFinder = inject(VariationFinder);

  edit(id: string, dt: MeterDefinitionStateI) {
    return this.cascadeEdit(id, dt);
  }

  toFE(from: MeterDefinitionStateI): MeterDefinitionStateI {
    return {
      ...from,
      scenarioVariation: this.determineVariation(from.scenarioVariation),
    };
  }

  toBE(from: MeterDefinitionStateI): unknown {
    return this.modifyVarIfBasecase(from);
  }

  private modifyVarIfBasecase(d: MeterDefinitionStateI) {
    if (d.scenarioVariation === NO_VARIATION) d.scenarioVariation = null;
    return d as MeterDefinitionStateI;
  }

  private determineVariation(variationId: string): string {
    return variationId
      ? variationId
      : this.varFinder.getVariationName(variationId);
  }
}
