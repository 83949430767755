import { DialogService } from 'prosumer-app/libs/eyes-core/services/dialog.service';
import { TimeBlocksProfileHelperService } from 'prosumer-app/shared/services/time-blocks-profile-helper/time-blocks-profile-helper.service';
import { generateShortUID } from 'prosumer-app/shared/utils/profile.utils';
import { filter, startWith, take } from 'rxjs';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  forwardRef,
  inject,
  input,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormControl,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

import {
  ColumnDefInterface,
  TimeBlocksYearlyInputComponent,
} from '../time-blocks-yearly-input';
import { YearlyLoadsIntervalDialogComponent } from '../yearly-loads-interval/yearly-loads-interval-dialog/yearly-loads-interval-dialog.component';
import { YearlyLoadsIntervalDialogModel } from '../yearly-loads-interval/yearly-loads-interval-dialog/yearly-loads-interval-dialog.model';
import { TimeBlocksIntervalI } from './time-blocks-interval.model';

const DEFAULT_TAB_INDEX = 0;

@Component({
  selector: 'prosumer-time-blocks-interval',
  imports: [
    TimeBlocksYearlyInputComponent,
    ReactiveFormsModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './time-blocks-interval.component.html',
  styleUrl: './time-blocks-interval.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeBlocksIntervalComponent),
      multi: true,
    },
  ],
})
export class TimeBlocksIntervalComponent
  implements OnInit, ControlValueAccessor
{
  private readonly profileHelper = inject(TimeBlocksProfileHelperService);
  private readonly dialogService = inject(DialogService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly fb = inject(FormBuilder);

  readonly columnDef = input<ColumnDefInterface[]>();
  readonly emptyDataMesssage = input<string>();
  readonly isViewOnly = input<boolean>(false);
  readonly startYearPeriod = input<number>();
  readonly endYearPeriod = input<number>();
  readonly binaryLocation = input<string>();
  readonly isLoading = input<boolean>(false);

  private readonly binaryLs$ = toObservable(this.profileHelper.binaryDataList);
  readonly isBinaryLoading = computed(() => this.profileHelper.binaryLoading());

  readonly tbIntervalsFormArray: UntypedFormArray = this.fb.array([]);
  readonly selectedTab = new UntypedFormControl(DEFAULT_TAB_INDEX);

  writeValue(v: TimeBlocksIntervalI[]): void {
    if (!v || !v.length) return;
    this.handleInitCtrls(v);
    this.profileHelper.registerIntervals(v);
  }
  registerOnTouched(fn: () => void): void {}
  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  onChange(v: TimeBlocksIntervalI[]) {}

  ngOnInit(): void {
    this.subToFormChange();
    this.profileHelper.registerBinaryLocation(this.binaryLocation());
    this.subToHandleBinaryLoading();
  }

  onEditIntervals() {
    const g = {
      startYear: this.startYearPeriod(),
      endYear: this.endYearPeriod(),
      intervals: this.mapToStart(this.tbIntervalsFormArray.value),
      width: 800,
      disableClose: true,
    } as YearlyLoadsIntervalDialogModel;
    this.dialogService
      .openDialog(YearlyLoadsIntervalDialogComponent, g)
      .pipe(
        take(1),
        filter((d) => !!d),
      )
      .subscribe((intervals) => {
        this.handleInitCtrls(
          this.mapToEnd(intervals, this.profileHelper.initialIntervalValue()),
        );
      });
  }

  private mapToStart(d) {
    return d.map((e) => ({
      startYear: e.startYear,
      endYear: e.endYear,
      profile: e,
    }));
  }

  private mapToEnd(d, defaultData = undefined) {
    return d.map((e) => ({
      startYear: e.profile.startYear,
      endYear: e.profile.endYear,
      data: e.profile.data === undefined ? defaultData : e.profile.data,
      localId: e.profile.localId || undefined,
    }));
  }

  private subToFormChange() {
    this.tbIntervalsFormArray.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((d) => this.onChange(d));
  }

  private handleInitCtrls(dt: TimeBlocksIntervalI[]) {
    this.tbIntervalsFormArray.clear();
    dt.forEach((e) => {
      const ctrl = this.fb.group({
        startYear: e.startYear || this.startYearPeriod(),
        endYear: e.endYear || this.endYearPeriod(),
        data: this.fb.control(e.data || false),
        localId: e.localId || generateShortUID(),
        location: this.binaryLocation(),
      });
      this.tbIntervalsFormArray.push(ctrl);
    });
    this.cd.markForCheck();
  }

  private subToHandleBinaryLoading() {
    this.selectedTab.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), startWith(DEFAULT_TAB_INDEX))
      .subscribe((i) => this.profileHelper.updateTabIndex(i));
    this.binaryLs$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((d) =>
        d.forEach((e, i) =>
          e ? this.tbIntervalsFormArray.at(i).patchValue({ data: e }) : null,
        ),
      );
  }
}
