<div fxLayout="column" fxLayoutAlign="start stretch">
  <mat-button-toggle-group
    class="visualizer-slice-selector"
    [formControl]="sliceControl"
    [hideSingleSelectionIndicator]="true"
    data-testid="sizing-and-cost-toggle-group"
  >
    <mat-button-toggle
      *ngFor="let option of options"
      [value]="option"
      [attr.data-testid]="'sizing-and-cost-' + option"
    >
      {{ 'Result.slices.' + option | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>

  <div
    class="sizing-and-costs-content"
    fxLayout="row"
    fxLayoutAlign="space-between stretch"
    fxLayoutGap="16px"
  >
    <prosumer-stacked-bar-chartjs
      fxFlex="60"
      [colors]="colors"
      [id]="'sizing-chart'"
      [data]="sizing$ | async"
      [emitLegendClicks]="false"
      scenarioName="{{ scenarioName }}"
      resultsName="{{ 'main_sizing_' + sliceControl.value }}"
      data-testid="sizing-container-chart"
    ></prosumer-stacked-bar-chartjs>
    <prosumer-pie-chartjs
      fxFlex="20"
      [id]="'capex-chart'"
      [colors]="colors"
      [emitLegendClicks]="false"
      [data]="capex$ | async"
      scenarioName="{{ scenarioName }}"
      resultsName="{{ 'main_CAPEX_' + sliceControl.value }}"
      data-testid="capex-container-chart"
    ></prosumer-pie-chartjs>
    <prosumer-pie-chartjs
      fxFlex="20"
      [id]="'opex-chart'"
      [colors]="colors"
      [data]="opex$ | async"
      [emitLegendClicks]="false"
      scenarioName="{{ scenarioName }}"
      resultsName="{{ 'main_OPEX_' + sliceControl.value }}"
      data-testid="opex-container-chart"
    ></prosumer-pie-chartjs>
  </div>
</div>
