<!-- Meter Definitions -->
<mat-expansion-panel [expanded]="expanded">
  <mat-expansion-panel-header
    [collapsedHeight]="collapsedHeight"
    [expandedHeight]="expandedHeight"
  >
    <mat-panel-title data-testid="meter-definitions-panel-title">
      {{ 'Scenario.labels.wizard.meters.headers.meterDefinitions' | translate }}
      <prosumer-tooltip-anchor
        [message]="'wizard_meters.wizard_meters_meter_definitions'"
      >
      </prosumer-tooltip-anchor>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <prosumer-meter-definitions-table
    [isViewOnly]="isViewMode()"
    [refsDt]="refsMeters()"
  ></prosumer-meter-definitions-table>
</mat-expansion-panel>

<!-- Meter Cost and Bound -->
<mat-expansion-panel [expanded]="expanded">
  <mat-expansion-panel-header
    [collapsedHeight]="collapsedHeight"
    [expandedHeight]="expandedHeight"
  >
    <mat-panel-title data-testid="meter-cost-and-bound-panel-title">
      {{ 'Scenario.labels.wizard.meters.headers.costAndBound' | translate }}
      <prosumer-tooltip-anchor
        [message]="'wizard_meters.wizard_meters_meter_cost_and_bounds'"
      >
      </prosumer-tooltip-anchor>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <prosumer-meter-cost-and-bound-table
    [isViewOnly]="isViewMode()"
    [refsDt]="refsMeters()"
  ></prosumer-meter-cost-and-bound-table>
</mat-expansion-panel>

<!-- Meter Tech And Markets -->
<mat-expansion-panel [expanded]="expanded">
  <mat-expansion-panel-header
    [collapsedHeight]="collapsedHeight"
    [expandedHeight]="expandedHeight"
  >
    <mat-panel-title data-testid="meter-technologies-and-markets-panel-title">
      {{
        'Scenario.labels.wizard.meters.headers.meterTechnologiesAndMarketFlow'
          | translate
      }}
      <prosumer-tooltip-anchor
        [message]="'wizard_meters.wizard_meters_meter_tech_and_markets'"
      >
      </prosumer-tooltip-anchor>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <prosumer-meter-technologies-and-markets-table
    [isMultiNode]="isMultiNode()"
    [isViewOnly]="isViewMode()"
    [refsDt]="refsMeters()"
  ></prosumer-meter-technologies-and-markets-table>
</mat-expansion-panel>

<prosumer-stepper-nav
  [disabled]="disabled"
  [previousDisabled]="previousDisabled"
  [previousLabel]="previousLabel"
  [nextDisabled]="nextDisabled"
  [nextLabel]="nextLabel"
  [nextColor]="nextColor"
  (previous)="onClickPrevious()"
  (next)="onClickNext()"
>
</prosumer-stepper-nav>
