import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'prosumer-time-partition-profile-summary',
  imports: [MatListModule, TranslateModule],
  templateUrl: './time-partition-profile-summary.component.html',
  styleUrl: './time-partition-profile-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePartitionProfileSummaryComponent {
  readonly profileValues = input<string[]>([]);
  readonly issues = input('-');

  readonly numberOfTBlocks = computed(() => new Set(this.profileValues()).size);
}
