import {
  MeterTechnologiesAndMarketsI,
  TechnologiesMarketsProfileDataI,
  TechnologiesMarketsValuesType,
} from 'prosumer-app/+scenario/models/meter-technologies-and-markets.model';
import { VariationFinder } from 'prosumer-app/+scenario/services/variation-finder.service';

import { inject, Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import { NO_VARIATION } from '../scenario-variation/scenario-variation.state';
import {
  MeterTechnologiesAndMarketsStateI,
  TechnologiesMarketsProfileDataStateI,
} from './meter-technologies-and-markets.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: ScenarioDetailType.meterTechnologiesAndMarkets,
  idKey: 'id',
})
export class MeterTechnologiesAndMarketsStore extends ScenarioDetailStore<MeterTechnologiesAndMarketsStateI> {
  private readonly varFinder = inject(VariationFinder);

  toFE(from: MeterTechnologiesAndMarketsI): MeterTechnologiesAndMarketsStateI {
    const { technologiesMarketsValues, ...to } = {
      ...from,
      scenarioVariation: this.determineVariation(from.scenarioVariation),
      profiles: this.mapTechnologiesMarketsValuesToProfiles(
        from.technologiesMarketsValues,
      ),
    };
    return to;
  }

  private determineVariation(variationId: string): string {
    return variationId
      ? variationId
      : this.varFinder.getVariationName(variationId);
  }

  private mapTechnologiesMarketsValuesToProfiles(
    d: TechnologiesMarketsValuesType,
  ): TechnologiesMarketsProfileDataStateI[] {
    return d?.profiles?.map((p) => this.mapProfileToFE(p));
  }

  private mapProfileToFE(
    profile: TechnologiesMarketsProfileDataI,
  ): TechnologiesMarketsProfileDataStateI {
    const { duration, year, ...p } = {
      ...profile,
      endYear: profile.year + profile.duration - 1,
      startYear: profile.year,
    };
    return p;
  }

  toBE(from: MeterTechnologiesAndMarketsStateI): MeterTechnologiesAndMarketsI {
    const { profiles, nodeIds, ...to } = {
      ...from,
      scenarioVariation: this.modifyVarIfBasecase(from.scenarioVariation),
      technologiesMarketsValues: this.mapProfilesToTechnologiesMarketsValues(
        from.profiles,
      ),
      nodeId: { nodeIds: from.nodeIds }, // do not ask why...
    };
    return to;
  }

  private mapProfilesToTechnologiesMarketsValues(
    profiles: TechnologiesMarketsProfileDataStateI[],
  ): TechnologiesMarketsValuesType {
    return {
      name: 'Technologies and Markets Profiles',
      profiles: profiles?.map((p) => this.mapProfileToBE(p)),
    };
  }

  private mapProfileToBE(
    profile: TechnologiesMarketsProfileDataStateI,
  ): TechnologiesMarketsProfileDataI {
    const { startYear, endYear, data, ...p } = {
      ...profile,
      duration: profile.endYear - profile.startYear + 1,
      year: profile.startYear,
    };
    return p;
  }

  private modifyVarIfBasecase(v: string) {
    if (v === NO_VARIATION) return null;
    return v;
  }
}
