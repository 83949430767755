<div class="container-summary h-full p-2">
  <h3 class="mb-0">
    {{
      'Scenario.labels.wizard.time.timePartition.dialog.summary.title'
        | translate
    }}
  </h3>
  <mat-list>
    <mat-list-item>
      <span class="font-semibold mr-2">Number of Time Blocks:</span>
      <span>{{ numberOfTBlocks() }}</span>
    </mat-list-item>
    <mat-list-item>
      <span class="font-semibold mr-2">Issues:</span>
      <span>{{ issues() }}</span>
    </mat-list-item>
  </mat-list>
</div>
