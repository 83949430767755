import { MeterDefinitionI } from 'prosumer-app/+scenario/models/meter-definition.model';
import { MeterDefinitionInfoService } from 'prosumer-app/+scenario/services/meter-definition-info';
import { DialogService } from 'prosumer-app/libs/eyes-core/services/dialog.service';
import { TableRefs } from 'prosumer-app/services/references-builder';
import { SharedModule } from 'prosumer-app/shared/shared.module';
import { take } from 'rxjs/operators';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { colDefMeterDefinitions } from '../configs/meter-definitions.token';
import { MeterDefinitionDialogComponent } from '../meter-definition-dialog';

@Component({
  selector: 'prosumer-meter-definitions-table',
  imports: [CommonModule, SharedModule],
  templateUrl: './meter-definitions-table.component.html',
  styleUrl: './meter-definitions-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeterDefinitionsTableComponent {
  private readonly dialogService = inject(DialogService);
  private readonly infoService = inject(MeterDefinitionInfoService);

  readonly isViewOnly = input<boolean>();
  readonly refsDt =
    input<Pick<TableRefs, 'timePartitions' | 'scenarioVariations'>>();

  readonly columnsDef = colDefMeterDefinitions;

  readonly data = toSignal(this.infoService.selectAllForActiveScenario());
  readonly isLoading = toSignal(this.infoService.isLoadingStore());

  onOpenDialog(
    isViewOnly: boolean,
    mode: 'edit' | 'add',
    e: MeterDefinitionI = null,
  ) {
    this.dialogService
      .openDialog(MeterDefinitionDialogComponent, {
        width: 700,
        mode,
        isViewOnly: isViewOnly,
        entity: e,
      })
      .pipe(take(1))
      .subscribe((md) => this.infoService.postUpdate(md));
  }

  onDelete(e: MeterDefinitionI) {
    this.infoService.deleteOne(e.id);
  }
}
