import { MeterTechnologiesAndMarketsI } from 'prosumer-app/+scenario/models/meter-technologies-and-markets.model';
import { MeterTechnologiesAndMarketsInfoService } from 'prosumer-app/+scenario/services/meter-technologies-and-markets-info';
import { DialogService } from 'prosumer-app/libs/eyes-core/services/dialog.service';
import { TableRefs } from 'prosumer-app/services/references-builder';
import { SharedModule } from 'prosumer-app/shared/shared.module';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { colDefMeterTechAndMarkets } from '../configs/meter-technologies-and-markets.token';
import { MeterTechnologiesAndMarketsDialogComponent } from '../meter-technologies-and-markets-dialog';

@Component({
  selector: 'prosumer-meter-technologies-and-markets-table',
  imports: [CommonModule, SharedModule],
  templateUrl: './meter-technologies-and-markets-table.component.html',
  styleUrl: './meter-technologies-and-markets-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeterTechnologiesAndMarketsTableComponent {
  private readonly dialogService = inject(DialogService);
  private readonly infoService = inject(MeterTechnologiesAndMarketsInfoService);

  readonly isMultiNode = input<boolean>();
  readonly isViewOnly = input<boolean>();
  readonly refsDt =
    input<Pick<TableRefs, 'meterDefinitions' | 'scenarioVariations'>>();

  readonly columnsDef = colDefMeterTechAndMarkets;

  readonly data = toSignal(this.infoService.selectAllForActiveScenario());
  readonly isLoading = toSignal(this.infoService.isLoadingStore());

  onOpenDialog(
    isViewOnly: boolean,
    isMultiNode: boolean,
    mode: 'edit' | 'add',
    e: MeterTechnologiesAndMarketsI = null,
  ) {
    this.dialogService
      .openDialog(MeterTechnologiesAndMarketsDialogComponent, {
        width: 1000,
        mode,
        isViewOnly: isViewOnly,
        isMultiNode: isMultiNode,
        entity: e,
      })
      .subscribe();
  }

  onDelete(e: MeterTechnologiesAndMarketsI) {
    this.infoService.deleteOne(e.id);
  }
}
