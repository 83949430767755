import { ColumnDefinition } from 'prosumer-libs/eyes-shared/components/base-table/base-table.model';

export const colDefMeterCostAndBound: ColumnDefinition = {
  meterId: {
    name: 'Meter Definition',
    type: 'reference',
    referenceKey: 'meterDefinitions',
    toolTip:
      'wizard_meters.wizard_meters_meter_cost_and_bounds_meter_definition',
    sortable: true,
  },
  scenarioVariation: {
    name: 'Variation',
    type: 'reference',
    referenceKey: 'scenarioVariations',
    sortable: true,
  },
  actions: {
    name: 'Actions',
    type: 'action',
    flex: '160px',
  },
};
