import { EnergyGridConnection } from 'prosumer-app/+scenario/models';
import { VariationFinder } from 'prosumer-app/+scenario/services';
import { EnergyGridConnectionQuery } from 'prosumer-app/stores/energy-grid-connection';
import { EnergyVectorQuery } from 'prosumer-app/stores/energy-vector';
import { EquipmentQuery } from 'prosumer-app/stores/equipment';

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ReservesFormService {
  constructor(
    private readonly energyVector: EnergyVectorQuery,
    private readonly equipmentQuery: EquipmentQuery,
    private readonly egcs: EnergyGridConnectionQuery,
    private readonly varFinder: VariationFinder,
  ) {}

  // prepForReserveNetworkDialog(): OptsForReserveNetwork {
  //   return {
  //     energyVectorOptions: this.energyVector.getVectorOptions(),
  //     currentReserveNetworkList: this.reserveNetowrk.getAllForActiveScenario(),
  //   };
  // }

  private buildMarketOpts(): EnergyGridConnection[] {
    let markets = this.egcs.getAllForActiveScenario();
    markets = this.injectVariationNames(markets);
    return markets;
  }

  private injectVariationNames(
    to: EnergyGridConnection[],
  ): EnergyGridConnection[] {
    return to.map((egc) => this.injectVariationName(egc));
  }

  private injectVariationName(to: EnergyGridConnection): EnergyGridConnection {
    return { ...to, marketName: this.buildMarketName(to) };
  }

  private buildMarketName(egc: EnergyGridConnection): string {
    return `${egc.marketName} (${this.varFinder.getVariationName(
      egc.scenarioVariation,
    )})`;
  }
}
