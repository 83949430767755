import { Utils } from 'prosumer-core/utils';

import { containsSubstring } from 'prosumer-app/libs/eyes-shared/utilities/object.util';
import { TIME_HORIZON } from './time-form.tokens';

export class TimeFormExt {
  static isYearsNonDistinct(oldYears: number[], newYears: number[]): boolean {
    return String(newYears) === String(oldYears);
  }

  static isYearSelected(years: number[], year: number): boolean {
    return Utils.resolveToEmptyArray(years).includes(year);
  }

  static isLimitReached(years: number[]): boolean {
    return (
      Utils.resolveToEmptyArray(years).length >=
      TIME_HORIZON.optimizationYearsLimit
    );
  }

  static hasNoYearSelected(years: number[]): boolean {
    return Utils.resolveToEmptyArray(years).length === 0;
  }

  static countYearsSelectable(years: number[]): number {
    return (
      TIME_HORIZON.optimizationYearsLimit -
      Utils.resolveToEmptyArray(years).length
    );
  }

  static filterOptimizedYears(
    [start, end]: [number, number],
    optimizedYears: number[],
  ): number[] {
    optimizedYears = Utils.resolveToEmptyArray(optimizedYears);
    return optimizedYears.filter(
      (year: number) => start <= year && year <= end,
    );
  }

  static mapSortOptions(years: number[]): unknown[] {
    years = Utils.resolveToEmptyArray(years);
    return [...years]
      .sort()
      .map((year: number) => ({ name: year.toString(), value: year }));
  }

  static getNumbersInBetween([start, end]: [number, number]): number[] {
    const dummyArr = [];
    for (let index = start; index <= end; index++) {
      dummyArr.push(index);
    }
    return dummyArr;
  }

  static numberIsBetween = (
    numberToCheck: number,
    lowNumber: number,
    highNumber: number,
  ): boolean => lowNumber <= numberToCheck && numberToCheck <= highNumber;

  static timePartitionSearchPredicate = (
    data: { name: string },
    filter: string,
  ) => containsSubstring(data.name, filter);
}
