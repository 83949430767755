import {
  CostAndBoundProfileDataI,
  CostAndBoundsValuesType,
  MeterCostAndBoundI,
} from 'prosumer-app/+scenario/models/meter-cost-and-bound.model';
import { VariationFinder } from 'prosumer-app/+scenario/services/variation-finder.service';

import { inject, Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import { NO_VARIATION } from '../scenario-variation/scenario-variation.state';
import {
  CostAndBoundProfileDataStateI,
  MeterCostAndBoundStateI,
} from './meter-cost-and-bound.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.meterCostAndBound, idKey: 'id' })
export class MeterCostAndBoundStore extends ScenarioDetailStore<MeterCostAndBoundStateI> {
  private readonly varFinder = inject(VariationFinder);

  toFE(from: MeterCostAndBoundI): MeterCostAndBoundStateI {
    const { costAndBoundsValues, ...to } = {
      ...from,
      scenarioVariation: this.determineVariation(from.scenarioVariation),
      profiles: this.mapCostAndBoundsValuesToProfiles(from.costAndBoundsValues),
    };
    return to;
  }

  mapCostAndBoundsValuesToProfiles(
    d: CostAndBoundsValuesType,
  ): CostAndBoundProfileDataStateI[] {
    return d?.profiles?.map((p) => this.mapProfileToFE(p));
  }

  mapProfileToFE(
    profile: CostAndBoundProfileDataI,
  ): CostAndBoundProfileDataStateI {
    const { duration, year, ...p } = {
      ...profile,
      endYear: profile.year + profile.duration - 1,
      startYear: profile.year,
    };
    return p;
  }

  toBE(from: MeterCostAndBoundStateI): MeterCostAndBoundI {
    const { profiles, ...to } = {
      ...from,
      scenarioVariation: this.modifyVarIfBasecase(from.scenarioVariation),
      costAndBoundsValues: this.mapProfilesToCostAndBoundsValues(from.profiles),
    };
    return to;
  }

  mapProfilesToCostAndBoundsValues(
    profiles: CostAndBoundProfileDataStateI[],
  ): CostAndBoundsValuesType {
    return {
      name: 'Cost and Bounds Profiles',
      profiles: profiles?.map((p) => this.mapProfileToBE(p)),
    };
  }

  mapProfileToBE(
    profile: CostAndBoundProfileDataStateI,
  ): CostAndBoundProfileDataI {
    const { startYear, endYear, data, ...p } = {
      ...profile,
      duration: profile.endYear - profile.startYear + 1,
      year: profile.startYear,
    };
    return p;
  }

  private modifyVarIfBasecase(v: string) {
    if (v === NO_VARIATION) return null;
    return v;
  }

  private determineVariation(variationId: string): string {
    return variationId
      ? variationId
      : this.varFinder.getVariationName(variationId);
  }
}
