import { MeterCostAndBoundDialogData } from 'prosumer-app/+scenario/models/meter-cost-and-bound.model';
import { MeterCostAndBoundInfoService } from 'prosumer-app/+scenario/services/meter-cost-and-bound-info';
import { MeterDefinitionInfoService } from 'prosumer-app/+scenario/services/meter-definition-info';
import { TimePartitionInfoService } from 'prosumer-app/+scenario/services/time-partition-info';
import { VariationInfoService } from 'prosumer-app/+scenario/services/variation-info';
import { BINARY_LOCATIONS } from 'prosumer-app/app.references';
import { BaseComponent } from 'prosumer-app/libs/eyes-shared/components/base-component/base.component';
import {
  FormFieldErrorMessageMap,
  FormFieldOption,
} from 'prosumer-app/libs/eyes-shared/components/form-field/form-field.model';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared/eyes-shared.module';
import { TimeBlocksIntervalComponent } from 'prosumer-app/shared/components/time-blocks-interval';
import { SharedModule } from 'prosumer-app/shared/shared.module';
import { MeterCostAndBoundCreator } from 'prosumer-app/stores/meter-cost-and-bound/meter-cost-and-bound.creator';
import { provideUpserter } from 'prosumer-app/stores/scenario-detail/scenario-detail.tokens';
import { ScenarioGenericQuery } from 'prosumer-app/stores/scenario-generic';
import { EntityUpsertButtonModule } from 'prosumer-shared/components/entity-upsert-button/entity-upsert-button.module';
import { filter, tap } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { COST_AND_BOUND_COLUMN_DEF } from './config';

@Component({
  selector: 'prosumer-meter-cost-and-bound-dialog',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    EyesSharedModule,
    SharedModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatFormFieldModule,
    MatProgressBarModule,
    TimeBlocksIntervalComponent,
    EntityUpsertButtonModule,
  ],
  providers: [provideUpserter(MeterCostAndBoundCreator)],
  templateUrl: './meter-cost-and-bound-dialog.component.html',
  styleUrl: './meter-cost-and-bound-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeterCostAndBoundDialogComponent
  extends BaseComponent
  implements OnInit
{
  private readonly meterCostAndBoundInfo = inject(MeterCostAndBoundInfoService);
  private readonly meterDefinitionInfo = inject(MeterDefinitionInfoService);
  private readonly timePartitionInfo = inject(TimePartitionInfoService);
  private readonly variationInfo = inject(VariationInfoService);
  private readonly scenarioQuery = inject(ScenarioGenericQuery);
  private readonly translateService = inject(TranslateService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly fb = inject(FormBuilder);
  readonly dialogRef = inject(MatDialogRef<MeterCostAndBoundDialogComponent>);
  readonly data = inject<MeterCostAndBoundDialogData>(MAT_DIALOG_DATA);

  private readonly formSubmitted = signal<boolean>(false);
  readonly isViewOnly = signal<boolean>(false);
  readonly activePeriod = toSignal(this.scenarioQuery.selectActivePeriod());
  readonly isLoading = toSignal(this.meterCostAndBoundInfo.isLoadingStore());
  readonly meterDefinitionDt = toSignal(
    this.meterDefinitionInfo.selectMappedForFormSelection(),
  );
  readonly scenarioVariationsDt = signal<FormFieldOption<string>[]>(null);
  private readonly entityOnEdit = toSignal(this.registerEntity(this.data));
  readonly onEditLoading = computed(() => this.entityOnEdit()?.loading);
  readonly isTPLoading = toSignal(this.timePartitionInfo.selectLoading());

  meterCostAndBoundForm: UntypedFormGroup;
  readonly profilesDataLocation =
    BINARY_LOCATIONS.COST_AND_BOUNDS_PROFILE_LOCATION;
  readonly columnDef = COST_AND_BOUND_COLUMN_DEF;

  ngOnInit(): void {
    this.initForm();
    this.handleScenarioVariationsExist();
    this.handleEntityOnEditMode(this.data);
    this.subToMeterSelectionChange();
    this.setViewOnlyState(this.data.isViewOnly);
  }

  onClose() {
    this.dialogRef.close();
  }

  onSaveAttempt() {
    this.formSubmitted.set(true);
  }

  private initForm() {
    this.meterCostAndBoundForm = this.fb.group({
      meterId: this.fb.control('', [Validators.required]),
      profiles: this.fb.control([], [Validators.required]),
    });
  }

  private handleScenarioVariationsExist() {
    const vars = this.variationInfo.getAllForActiveScenario();
    if (vars.length > 1) {
      this.scenarioVariationsDt.set(vars);
      this.registerScenarioVariationCtrl(vars);
    }
  }

  private registerScenarioVariationCtrl(variations: FormFieldOption<string>[]) {
    this.meterCostAndBoundForm.addControl(
      'scenarioVariation',
      this.fb.control(variations[0].value, [Validators.required]),
    );
  }

  private handleEntityOnEditMode(dt: MeterCostAndBoundDialogData) {
    if (dt.mode === 'edit' && dt.entity) {
      this.meterCostAndBoundInfo.getSingle(dt.entity.id);
    }
  }

  private registerEntity(dt: MeterCostAndBoundDialogData) {
    return this.meterCostAndBoundInfo.selectEntity(dt.entity?.id).pipe(
      filter(() => !this.formSubmitted()),
      tap((e) =>
        this.meterCostAndBoundInfo.patchForm(e, this.meterCostAndBoundForm),
      ),
    );
  }

  private subToMeterSelectionChange() {
    this.meterCostAndBoundForm
      .get('meterId')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((mdId) => this.fetchUniqueTimeBlocks(mdId));
  }

  private fetchUniqueTimeBlocks(meterId: string) {
    const tpId = this.meterDefinitionInfo.getEntity(meterId)['timePartitionId'];
    this.timePartitionInfo
      .getUniqueTBsOfATimePartition(tpId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((tp) => {
        this.meterCostAndBoundForm
          .get('profiles')
          .patchValue([{ data: this.mapTimeBlocksToCtrlFormat(tp) }]);
      });
  }

  private mapTimeBlocksToCtrlFormat(d: string[]) {
    return d.map((e) => ({
      timeBlock: e,
      readValue: '',
      minValue: '',
      maxValue: '',
    }));
  }

  private setViewOnlyState(v: boolean) {
    this.isViewOnly.set(v);
  }

  readonly errorMessages: FormFieldErrorMessageMap = {
    meterId: {
      required: this.translateService.instant(
        'Scenario.dialog.meters.errors.meterCostAndBound.meterId.required',
      ),
      // unique: this.translateService.instant(
      //   'Scenario.dialog.meters.errors.meterCostAndBound.meterId.unique',
      // ),
    },
    scenarioVariation: {
      required: this.translateService.instant(
        'Scenario.dialog.meters.errors.meterCostAndBound.scenarioVariation.required',
      ),
      // unique: this.translateService.instant(
      //   'Scenario.dialog.meters.errors.meterCostAndBound.scenarioVariation.unique',
      // ),
    },
    profiles: {
      required: this.translateService.instant(
        'Scenario.dialog.meters.errors.meterCostAndBound.profiles.required',
      ),
    },
  };
}
