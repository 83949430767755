import {
  DefaultIntervalI,
  TimeBlocksIntervalI,
} from 'prosumer-app/shared/components/time-blocks-interval/time-blocks-interval.model';
import { ScenarioGenericQuery } from 'prosumer-app/stores/scenario-generic';
import { ScenarioBinStore } from 'prosumer-app/stores/scenario_binary/scenarioBin-store.service';
import { filter, take } from 'rxjs';

import { computed, effect, inject, Injectable, signal } from '@angular/core';

@Injectable({ providedIn: 'any' })
export class TimeBlocksProfileHelperService {
  private readonly binStore = inject(ScenarioBinStore);
  private readonly scenarioQuery = inject(ScenarioGenericQuery);

  private readonly intervalsDt = signal<TimeBlocksIntervalI[]>([]);
  private readonly binaryLocation = signal<string>(null);
  private readonly tabIndex = signal<number>(null);
  readonly binaryLoading = signal<boolean>(false);

  readonly binaryDataList = computed<DefaultIntervalI[][]>(() =>
    this.intervalsDt().map((interval) => interval.data),
  );
  readonly initialIntervalValue = computed(() =>
    this.extractInitialIntervalValue(this.intervalsDt()),
  );

  constructor() {
    effect(() => this.handleBinaryLoading(this.tabIndex(), this.intervalsDt()));
  }

  private handleBinaryLoading(i: number, d: TimeBlocksIntervalI[]) {
    if (!d || !d.length || i === null) return;
    const e = d[i];
    if (e && !e.data) this.loadBin(e.localId, i);
  }

  registerIntervals(dt: TimeBlocksIntervalI[]) {
    this.intervalsDt.set(dt);
  }

  registerBinaryLocation(loc: string) {
    this.binaryLocation.set(loc);
  }

  updateTabIndex(i: number) {
    this.tabIndex.set(i);
  }

  private loadBin(localId: string, i: number) {
    if (!localId) return;
    const scenInfo = this.scenarioQuery.getActive();
    this.binaryLoading.set(true);
    this.binStore
      .get(
        scenInfo.projectUuid,
        scenInfo.caseUuid,
        scenInfo.scenarioUuid,
        this.binaryLocation(),
        localId,
        true,
      )
      .pipe(
        filter((d) => !!d),
        take(1),
      )
      .subscribe({
        next: (profile) =>
          this.intervalsDt.update((d) =>
            d.map((interval, index) =>
              index === i
                ? this.updateItnervalData(interval, profile)
                : interval,
            ),
          ),
        complete: () => this.binaryLoading.set(false),
      });
  }

  private updateItnervalData(existing: TimeBlocksIntervalI, profile: any) {
    return { ...existing, data: profile.data };
  }

  private extractInitialIntervalValue(dt: TimeBlocksIntervalI[]) {
    return dt[0]?.data?.map((e) => {
      const r = {};
      Object.keys(e).forEach((k) => {
        if (k === 'timeBlock') {
          r[k] = e[k];
        } else {
          r[k] = '';
        }
      });
      return r;
    });
  }
}
