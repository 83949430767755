@if (isLoading() || onEditLoading()) {
  <mat-progress-bar
    color="accent"
    mode="indeterminate"
    data-testid="meterTechnologiesAndMarkets-dialog-progress-bar"
  ></mat-progress-bar>
}

<form
  [formGroup]="meterTechAndMarketsForm"
  data-testid="meterTechnologiesAndMarkets-dialog-form"
>
  <h2 mat-dialog-title fxLayout="row" class="container-title pt-3 mb-2">
    <h2 data-testid="meterTechnologiesAndMarkets-dialog-title">
      {{ data?.mode | titlecase }}
      {{
        'Scenario.dialog.meters.labels.technologiesAndMarkets.title' | translate
      }}
    </h2>
    <prosumer-tooltip-anchor
      [message]="'wizard_meters.wizard_meters_meter_tech_and_markets'"
      data-testid="meterTechnologiesAndMarkets-dialog-title-tooltip"
    ></prosumer-tooltip-anchor>
  </h2>

  <mat-dialog-content class="container-content mb-4">
    <div class="formgrid grid">
      <div class="field col-6 mb-0">
        <prosumer-select
          [required]="true"
          [options]="meterDefinitionDt()"
          [control]="meterTechAndMarketsForm?.controls?.meterId"
          [label]="
            'Scenario.dialog.meters.labels.technologiesAndMarkets.meterId'
              | translate
          "
          [placeholder]="
            'Scenario.dialog.meters.placeholders.technologiesAndMarkets.meterId'
              | translate
          "
          [tooltip]="
            'wizard_meters.wizard_meters_meter_tech_and_markets_meter_definition'
          "
          [errorMessageMap]="errorMessages.meterId"
          data-testid="technologiesAndMarkets-dialog-form-meterId"
        >
        </prosumer-select>
      </div>
      <div class="field col-6 mb-0">
        <prosumer-select
          [required]="true"
          [options]="evDt()"
          [placeholder]="'Scenario.placeholders.energyVector' | translate"
          [label]="'Scenario.labels.energyVector' | translate"
          [control]="meterTechAndMarketsForm?.controls?.energyVectorId"
          [errorMessageMap]="errorMessages?.energyVectorId"
          [tooltip]="
            'wizard_meters.wizard_meters_tech_and_market_energy_vector'
              | translate
          "
          data-testid="technologiesAndMarkets-dialog-form-energyVectorId"
        ></prosumer-select>
      </div>
      <div class="field col-12 mb-0">
        @if (scenarioVariationsDt()) {
          <prosumer-select
            [required]="true"
            [placeholder]="
              'Scenario.dialog.meters.placeholders.technologiesAndMarkets.scenarioVariation'
                | translate
            "
            [label]="
              'Scenario.dialog.meters.labels.technologiesAndMarkets.scenarioVariation'
                | translate
            "
            [options]="scenarioVariationsDt()"
            [control]="meterTechAndMarketsForm?.controls?.scenarioVariation"
            [errorMessageMap]="errorMessages.scenarioVariation"
            data-testid="technologiesAndMarkets-dialog-form-scenarioVariation"
          ></prosumer-select>
        }
      </div>
      <div class="field col-6 mb-0">
        <prosumer-multi-select
          [formControl]="meterTechAndMarketsForm?.get('equipments')"
          [options]="filteredEquipDt()"
          [labelText]="
            'Scenario.dialog.meters.labels.technologiesAndMarkets.equipments'
              | translate
          "
          [placeholder]="
            'Scenario.dialog.meters.placeholders.technologiesAndMarkets.equipments'
              | translate
          "
          [tooltip]="
            'wizard_meters.wizard_meters_meter_tech_and_markets_equipment'
              | translate
          "
          [errors]="meterTechAndMarketsForm.get('equipments').errors"
          [errorMessageMap]="errorMessages.equipments"
          data-testid="technologiesAndMarkets-dialog-form-equipments"
        ></prosumer-multi-select>
      </div>
      <div class="field col-6 mb-0">
        <prosumer-multi-select
          [formControl]="meterTechAndMarketsForm?.get('energyGridConnections')"
          [options]="filteredEgcDt()"
          [labelText]="
            'Scenario.dialog.meters.labels.technologiesAndMarkets.energyGridConnections'
              | translate
          "
          [placeholder]="
            'Scenario.dialog.meters.placeholders.technologiesAndMarkets.energyGridConnections'
              | translate
          "
          [tooltip]="
            'wizard_meters.wizard_meters_meter_tech_and_markets_egc' | translate
          "
          [errors]="meterTechAndMarketsForm.get('energyGridConnections').errors"
          [errorMessageMap]="errorMessages.energyGridConnections"
          data-testid="technologiesAndMarkets-dialog-form-energyGridConnections"
        ></prosumer-multi-select>
      </div>
      @if (this.data.isMultiNode) {
        <div class="field col-12 mb-0">
          <prosumer-multi-select
            [formControl]="meterTechAndMarketsForm?.get('nodeIds')"
            [options]="nodeDt()"
            [required]="true"
            [allowAllSelection]="true"
            [errorMessageMap]="errorMessages.nodeIds"
            [labelText]="
              'Scenario.dialog.meters.labels.technologiesAndMarkets.nodeIds'
                | translate
            "
            [placeholder]="
              'Scenario.dialog.meters.placeholders.technologiesAndMarkets.nodeIds'
                | translate
            "
            data-testid="technologiesAndMarkets-dialog-form-nodeIds"
          ></prosumer-multi-select>
        </div>
      }
    </div>
    <div class="field">
      <prosumer-time-blocks-interval
        [columnDef]="columnDef"
        [formControl]="meterTechAndMarketsForm?.get('profiles')"
        [startYearPeriod]="activePeriod()[0]"
        [endYearPeriod]="activePeriod()[1]"
        [binaryLocation]="profilesDataLocation"
        [emptyDataMesssage]="
          'Scenario.dialog.meters.labels.technologiesAndMarkets.emptyUniqueTimeBlocksList'
            | translate
        "
        data-testid="technologiesAndMarkets-dialog-form-timeBlocks"
      ></prosumer-time-blocks-interval>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" class="gap-2 pb-5">
    @if (!data.isViewOnly) {
      <prosumer-entity-upsert-button
        [id]="data?.entity?.id"
        [mode]="data?.mode"
        [data]="meterTechAndMarketsForm.value"
        [valid]="meterTechAndMarketsForm.valid"
        [disabled]="
          meterTechAndMarketsForm.pristine || meterTechAndMarketsForm.invalid
        "
        (ok)="onClose()"
        (attempt)="onSaveAttempt()"
      ></prosumer-entity-upsert-button>
    }
    <button
      mat-flat-button
      type="button"
      [disabled]="isLoading() || onEditLoading()"
      (click)="onClose()"
      data-testid="meterTechnologiesAndMarkets-dialog-form-btn-cancel"
    >
      {{ 'Generic.labels.close' | translate }}
    </button>
  </mat-dialog-actions>
</form>
