import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';

import { inject, Injectable } from '@angular/core';

import { ScenarioDetailQuery } from '../scenario-detail';
import { MeterTechnologiesAndMarketsStateI } from './meter-technologies-and-markets.state';
import { MeterTechnologiesAndMarketsStore } from './meter-technologies-and-markets.store';

@Injectable({ providedIn: 'root' })
export class MeterTechnologiesAndMarketsQuery extends ScenarioDetailQuery<MeterTechnologiesAndMarketsStateI> {
  constructor() {
    const keeper = inject(ActiveKeeperService);
    const store = inject(MeterTechnologiesAndMarketsStore);
    super(store, keeper);
  }
}
