<div
  class="visualizer-container"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="16px"
>
  <div
    fxFlex
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    fxLayoutGap="16px"
  >
    <prosumer-chips
      fxFlex
      *ngFor="let filter of filterBies$ | async"
      [label]="
        ('Result.labels.filterBy' | translate) +
        ' ' +
        ('Result.labels.' + filter?.label | translate)
      "
      [options]="filter?.options"
      (chips)="onFilterChange(filter?.label, $event)"
      [attr.data-testid]="
        'filter-chip-' + ('Result.labels.' + filter?.label | translate)
      "
    ></prosumer-chips>
  </div>
  <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="16px">
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
      <mat-button-toggle-group
        vertical
        class="visualizer-slice-selector"
        [formControl]="sliceControl"
        [hideSingleSelectionIndicator]="true"
        data-testid="visualizer-slice-selector"
      >
        <mat-button-toggle
          *ngFor="let slice of slices"
          [value]="slice?.name"
          [attr.data-testid]="'visualizer-slice-selector-' + slice?.name"
        >
          {{ 'Result.slices.' + slice?.name | translate }}
          <span *ngIf="slice?.unit">({{ slice?.unit }})</span>
          <prosumer-tooltip-anchor
            [message]="slice?.tooltip | translate"
          ></prosumer-tooltip-anchor>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <div
        class="visualizer-controls"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="8px"
      >
        <mat-button-toggle-group
          [formControl]="resultTypeControl"
          [hideSingleSelectionIndicator]="true"
          data-testid="visualizer-controls"
        >
          <mat-button-toggle
            *ngFor="let type of resultTypes"
            [value]="type"
            [attr.data-testid]="'visualizer-controls-' + type"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
              <mat-icon aria-hidden="false" color="primary">{{
                (resultTypeIconMap || {})[type]
              }}</mat-icon>
              <span class="visualizer-type-toggle">{{ type | uppercase }}</span>
            </div>
          </mat-button-toggle>
        </mat-button-toggle-group>
        <mat-form-field>
          <mat-label data-testid="visualizer-year-select"
            >Year to show</mat-label
          >
          <mat-select
            [formControl]="activeYearControl"
            [hideSingleSelectionIndicator]="true"
            data-testid="visualizer-year-select"
          >
            <mat-option
              [hidden]="(isOverall$ | async) !== true"
              [value]="'Result.labels.all' | translate"
            >
              {{ 'Result.labels.all' | translate }}
            </mat-option>
            <mat-option
              *ngFor="let year of allYears$ | async"
              [value]="year"
              [attr.data-testid]="'visualizer-year-select-' + year"
              [hidden]="isOverall$ | async"
            >
              {{ year }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxFlex fxLayout="column" fxLayoutAlign="start stretch">
      <ng-content></ng-content>
    </div>
  </div>
</div>
