import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ValidatorFn } from '@iplab/ngx-file-upload';

export class CombinedInputsValidator {
  static eitherCtrlsDefined =
    (ctrlNames: string[]): ValidatorFn =>
    (group: AbstractControl): ValidationErrors | null => {
      const isValid = ctrlNames.some((name) => {
        const v = group.get(name).value;
        return Array.isArray(v) && v.length > 0;
      });
      if (isValid) {
        ctrlNames.forEach((n) => group.get(n).setErrors(null));
        return null;
      } else {
        ctrlNames.forEach((n) =>
          group.get(n).setErrors({ eitherCtrlUndefined: true }),
        );
        return { eitherCtrlUndefined: true };
      }
    };
}
