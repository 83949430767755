import { TooltipModule } from 'prosumer-app/libs/eyes-shared/modules/tooltip/tooltip.module';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { TimeBlocksInputHelperService } from 'prosumer-app/shared/services/time-blocks-input-helper';

import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  forwardRef,
  inject,
  input,
  OnInit,
  signal,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormArray,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { TimeBlockUtilService } from '../../utils/time-block-util/time-block-util.service';
import {
  ColumnDefInterface,
  TimeBlockDataType,
} from './time-blocks-yearly-input.model';

@Component({
  selector: 'prosumer-time-blocks-yearly-input',
  imports: [
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    ReactiveFormsModule,
    ClipboardModule,
    TooltipModule,
    TranslateModule,
    ScrollingModule,
    ScrollingModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './time-blocks-yearly-input.component.html',
  styleUrl: './time-blocks-yearly-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TimeBlocksInputHelperService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeBlocksYearlyInputComponent),
      multi: true,
    },
  ],
})
export class TimeBlocksYearlyInputComponent
  implements OnInit, ControlValueAccessor
{
  private readonly translate = inject(TranslateService);
  private readonly notifService = inject(NotificationsService);
  private readonly helper = inject(TimeBlocksInputHelperService);
  private readonly cb = inject(Clipboard);
  private readonly fb = inject(FormBuilder);
  readonly tbYearlyInputArrayForm: UntypedFormArray = this.fb.array([]);

  readonly isViewOnly = input<boolean>(false);
  readonly isLoading = input<boolean>(false);
  readonly columnDef = input<ColumnDefInterface[]>([]);
  readonly displayedColumnKeys = computed(() =>
    this.columnDef().map((e) => e.key),
  );
  readonly dataSource = signal<TimeBlockDataType>([]);
  readonly animateCopyBtn = signal(false);
  readonly animatePasteBtn = signal(false);

  writeValue(dl: TimeBlockDataType): void {
    if (!dl || !dl.length) return;
    this.handleArrayCtrlsInit(dl);
    this.dataSource.set(dl);
  }
  registerOnTouched(fn: () => void): void {}
  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  onChange(v: TimeBlockDataType) {}

  ngOnInit(): void {
    this.subToFormArrayChange();
  }

  trackByFn(idx: number, item: any) {
    return idx;
  }

  onCopy() {
    this.helper.animateCopyPasteBtnState(this.animateCopyBtn);
    this.cb.copy(
      TimeBlockUtilService.extractTimeBlockIntervalToExcelDt(
        this.tbYearlyInputArrayForm.value,
      ),
    );
  }

  async onPaste() {
    const ctrl = this.tbYearlyInputArrayForm;
    ctrl.disable();
    this.helper.animateCopyPasteBtnState(this.animatePasteBtn);
    const pasteDt = await navigator.clipboard.readText();
    const trDt = TimeBlockUtilService.transformPasteddData(pasteDt);
    if (
      this.helper.areArraysEqual(
        this.helper.extractValofKey(this.dataSource(), 'timeBlock'),
        this.helper.extractValofKey(trDt, 'timeBlock'),
      )
    ) {
      ctrl.patchValue(trDt);
      this.dataSource.set(trDt);
    } else {
      this.notifService.showError(
        this.translate.instant('Generic.messages.timeBlocks.onPasteIssue'),
      );
    }
    ctrl.enable();
  }

  private subToFormArrayChange() {
    this.tbYearlyInputArrayForm.valueChanges.subscribe((d) => this.onChange(d));
  }

  private handleArrayCtrlsInit(d: TimeBlockDataType) {
    this.tbYearlyInputArrayForm.clear();
    d.forEach((e) => {
      const group = this.fb.group({});
      Object.entries(e).forEach(([key, val]) =>
        group.addControl(key, this.fb.control(val)),
      );
      this.tbYearlyInputArrayForm.push(group);
    });
  }
}
