export const TECH_AND_MARKETS_COLUMN_DEF = [
  {
    name: 'Time Blocks',
    key: 'timeBlock',
    role: 'description',
    tooltip: 'wizard_meters.wizard_meters_meter_tech_and_markets_time_block',
  },
  {
    name: 'Input Coefficient',
    key: 'inputCoefficient',
    role: 'input',
    inputType: 'number',
    tooltip: 'wizard_meters.wizard_meters_meter_tech_and_markets_input_coef',
  },
  {
    name: 'Output Coefficient',
    key: 'outputCoefficient',
    role: 'input',
    inputType: 'number',
    tooltip: 'wizard_meters.wizard_meters_meter_tech_and_markets_output_coef',
  },
];
